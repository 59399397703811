import { Text } from '@chakra-ui/react';
import numeral from 'numeral';
import React from 'react';

type UtilPriceFormatterProps = {
  prefix?: string;
  suffix?: string;
  price: number;
  format?: string;
};

function UtilPriceFormatter({
  prefix = '฿',
  suffix = '',
  price = 0,
  format = '0,0',
}: UtilPriceFormatterProps) {
  return (
    <>
      {prefix ? (
        <>
          <Text as="span">{prefix}</Text>
          {numeral(price).format(format)}
        </>
      ) : suffix ? (
        <>
          {numeral(price).format(format)}
          <Text as="span">{suffix}</Text>
        </>
      ) : (
        numeral(price).format(format)
      )}
    </>
  );
}

export default UtilPriceFormatter;
