import {
  IUser,
  UserGenderEnum,
  UserStatusEnum,
} from 'interfaces/user.interface';
import create from 'zustand';

interface UserState {
  user: IUser | null;
  setUser: (payload: IUser | null) => void;
}

export const useUserStore = create<UserState>((set) => ({
  user: {
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    isAuthenticated: false,
    accessToken: '',
    birthDate: null,
    gender: UserGenderEnum.OTHER,
    passwordIsSet: false,
    passwordResetToken: null,
    addresses: [],
    status: UserStatusEnum.INACTIVE,
  },
  setUser: (payload: IUser | null) => set({ user: payload }),
}));
