import { Box, Button, Input } from '@chakra-ui/react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

function LayoutHeaderSearchInput() {
  // use react hook form
  const { register, setValue, handleSubmit } = useForm();

  // use router
  const router = useRouter();

  const handleSearchSubmit = (data: any) => {
    console.log('data', data);
    if (data.search) {
      router.push(`/search?query=${data.search}`);
    }

    return;
  };

  useEffect(() => {
    console.log('router', router);

    // set search value
    if (router.query.query) {
      setValue('search', router.query.query);
    }
  }, [router]);

  return (
    <Box position="relative">
      <form onSubmit={handleSubmit(handleSearchSubmit)}>
        <Input
          borderRadius="3xl"
          size="sm"
          bg="white"
          color="gray.800"
          border="0"
          height="30px"
          {...register('search')}
        ></Input>
        <Button
          position="absolute"
          top={0}
          right={0}
          height="100%"
          zIndex={2}
          variant="unstyled"
          pr={2}
          display="inline-flex"
          type="submit"
        >
          <Image
            src="/image-icon/search.png"
            width={19}
            height={20}
            alt="search"
          />
        </Button>
      </form>
    </Box>
  );
}

export default LayoutHeaderSearchInput;
