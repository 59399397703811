import { IPaginationResponse } from 'dtos/pagination.dto';
import { IWishlist } from 'interfaces/wishlist.interface';
import { useEffect } from 'react';
import { useWishlistStore } from 'store/wishlist.store';
import useSWR from 'swr';
import useUser from './useUser';

export default function useWishlist(query?: string) {
  // use user
  const { user } = useUser();

  // use wish list store
  const { wishlists, wishlistsTotal, setWishlists, setWishlistsTotal } =
    useWishlistStore();

  // get wishlists
  const { data: wishlistsData } = useSWR<IPaginationResponse<IWishlist>>(() =>
    user && user.accessToken
      ? {
          url: `${process.env.NEXT_PUBLIC_API_URL}/v1/public/profile/wishlists${
            query ? `?${query}` : ''
          }`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      : null
  );

  useEffect(() => {
    // log wishlists
    console.log('wishlists', wishlistsData);

    if (wishlistsData) {
      setWishlists(wishlistsData.docs);
      setWishlistsTotal(wishlistsData.totalDocs);
    }
  }, [wishlistsData]);

  return {
    wishlistsData,
    wishlists,
    wishlistsTotal,
  };
}
