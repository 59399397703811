import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Container,
  Heading,
  HStack,
  Link,
  SimpleGrid,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import LayoutFooterMenu from './LayoutFooterMenu';
import useSWR from 'swr';
import { ISetting } from 'interfaces/setting.interface';

function LayoutFooter() {
  const { t } = useTranslation('common');

  const [isDesktop] = useMediaQuery('(min-width: 992px)');

  // init current scroll position
  const [currentScrollPosition, setCurrentScrollPosition] = useState(0);

  // init current scroll height percentage
  const [currentScrollHeightPercentage, setCurrentScrollHeightPercentage] =
    useState(0);

  // use router
  const router = useRouter();

  const { locale } = router;

  const menu1 = useMemo(() => {
    return [
      {
        label: t('layout.footer.menu1.howToOrder', {
          // TH: วิธีการสั่งซื้อ
          // EN: How to order
        }),
        href: '/how-to-buy',
      },
      {
        label: t('layout.footer.menu1.paymentMethod', {
          // TH: วิธีการชำระเงิน
          // EN: Payment method
        }),
        href: '/how-to-pay',
      },
      {
        label: t('layout.footer.menu1.checkOrderStatus', {
          // TH: ตรวจสอบสถานะการสั่งซื้อ
          // EN: Check order status
        }),
        href: '/my-account/orders',
      },
      {
        label: t('layout.footer.menu1.shippingFee', {
          // TH: การจัดส่งสินค้าและค่าบริการ
          // EN: Shipping & Fee
        }),
        href: '/shipping-service-charge',
      },
      {
        label: t('layout.footer.menu1.returnsRefunds', {
          // TH: การเคลมและเปลี่ยนสินค้า
          // EN: Claims & Returns
        }),
        href: '/claim-replacement',
      },
      {
        label: t('layout.footer.menu1.warrantyPolicy', {
          // TH: นโยบายการรับประกันสินค้า
          // EN: Warranty policy
        }),
        href: '/warranty-policy',
      },
    ];
  }, [t]);

  const menu2 = useMemo(() => {
    return [
      {
        label: t('layout.footer.menu2.dealer', {
          // TH: ตัวแทนจำหน่าย
          // EN: Dealer
        }),
        href: '/agent',
      },
      {
        label: t('layout.footer.menu2.lbs', {
          // TH: LAMPTAN BRIGHT STUDIO
          // EN: LAMPTAN BRIGHT STUDIO
        }),
        href: 'https://www.google.com/maps/place/Lamptan+Bright+Studio/@13.6658413,100.4543676,13.53z/data=!4m6!3m5!1s0x30e2a2c7f713dd09:0xfda95b6119a67588!8m2!3d13.6691411!4d100.4517272!16s%2Fg%2F11g6j7d2t5?entry=ttu',
        target: '_blank',
      },
    ];
  }, [t]);

  const menu3 = useMemo(() => {
    return [
      {
        label: t('layout.footer.menu3.aboutUs', {
          // TH: เกี่ยวกับเรา
          // EN: About us
        }),
        href: '/about-us',
      },
      {
        label: t('layout.footer.menu3.workWithUs', {
          // TH: ร่วมงานกับเรา
          // EN: Work with us
        }),
        href: '/careers',
      },
      {
        label: t('layout.footer.menu3.privacyPolicy', {
          // TH: นโยบายความเป็นส่วนตัว
          // EN: Privacy policy
        }),
        href: '/privacy-policy',
      },
      {
        label: t('layout.footer.menu3.cookiePolicy', {
          // TH: นโยบายคุกกี้
          // EN: Cookie policy
        }),
        href: '/cookie-policy',
      },
    ];
  }, [t]);

  // handle scroll change
  useEffect(() => {
    const handleScroll = () => {
      setCurrentScrollPosition(window.scrollY);

      // get window height
      const windowHeight = window.innerHeight;

      // get scrollable height
      const scrollableHeight = document.body.scrollHeight - windowHeight;

      // get scroll percentage
      const scrollPercentage = (window.scrollY / scrollableHeight) * 100;

      // log window height
      console.log('windowHeight', windowHeight);

      // log scrollable height
      console.log('scrollableHeight', scrollableHeight);

      // log scroll percentage
      console.log('scrollPercentage', scrollPercentage);

      setCurrentScrollHeightPercentage(scrollPercentage);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // log currentScrollPosition
  useEffect(() => {
    console.log('currentScrollPosition', currentScrollPosition);
  }, [currentScrollPosition]);

  const { data: contactUsTh } = useSWR<ISetting>(
    locale === 'th'
      ? {
          url: `${process.env.NEXT_PUBLIC_API_URL}/v1/settings/contactUsTh`,
          method: 'GET',
        }
      : null
  );

  const { data: contactUsEn } = useSWR<ISetting>(
    locale === 'en'
      ? {
          url: `${process.env.NEXT_PUBLIC_API_URL}/v1/settings/contactUsEn`,
          method: 'GET',
        }
      : null
  );

  return (
    <>
      <Box>
        <Box
          height="50px"
          bgGradient="linear-gradient(to right, #EC5E3A 10%, #F18556 60%, #F4A268 100%)"
        >
          <Container height="inherit">
            <HStack color="white" height="inherit">
              <Link
                href="https://www.facebook.com/lamptanlighting/"
                target="_blank"
              >
                <Center>
                  <Image
                    src="/image-icon/social-fb.png"
                    alt="facebook"
                    width={36}
                    height={36}
                  ></Image>
                </Center>
              </Link>
              <Link
                href="https://liff.line.me/1645278921-kWRPP32q/?accountId=lamptan"
                target="_blank"
              >
                <Center>
                  <Image
                    src="/image-icon/social-line.png"
                    alt="facebook"
                    width={36}
                    height={36}
                  ></Image>
                </Center>
              </Link>
              <Link
                href="https://www.youtube.com/user/lamptan2001"
                target="_blank"
              >
                <Center>
                  <Image
                    src="/image-icon/social-youtube.png"
                    alt="youtube"
                    width={36}
                    height={36}
                  ></Image>
                </Center>
              </Link>
              <Link
                href="https://www.instagram.com/lamptan_official?igsh=MWd4YjVtMm91amNycw%3D%3D&utm_source=qr"
                target="_blank"
              >
                <Center>
                  <Image
                    src="/image-icon/social-ig.png"
                    alt="instagram"
                    width={36}
                    height={36}
                  ></Image>
                </Center>
              </Link>
              <Link
                href="https://www.tiktok.com/@lamptan_lighting?_t=8oTlB4rLjzl&_r=1"
                target="_blank"
              >
                <Center>
                  <Image
                    src="/image-icon/social-tiktok.png"
                    alt="tiktok"
                    width={36}
                    height={36}
                  ></Image>
                </Center>
              </Link>
            </HStack>
          </Container>
        </Box>

        <Container>
          {isDesktop ? (
            <HStack pt={6} alignItems="flex-start" pb={10}>
              <Box width={`${100 / 4}%`}>
                <Heading
                  fontSize="md"
                  mb={3}
                  color="primary.500"
                  textTransform="uppercase"
                >
                  {t('layout.footer.menu1.title', {
                    // TH: ดูแลลูกค้า
                    // EN: Customer care
                  })}
                </Heading>
                <LayoutFooterMenu menu={menu1}></LayoutFooterMenu>
              </Box>
              <Box width={`${100 / 4}%`}>
                <Heading
                  fontSize="md"
                  mb={3}
                  color="primary.500"
                  textTransform="uppercase"
                >
                  {t('layout.footer.menu2.title', {
                    // TH: สถานที่จำหน่าย
                    // EN: Dealer
                  })}
                </Heading>
                <LayoutFooterMenu menu={menu2}></LayoutFooterMenu>
              </Box>
              <Box width={`${100 / 4}%`}>
                <Heading
                  fontSize="md"
                  mb={3}
                  color="primary.500"
                  textTransform="uppercase"
                >
                  {t('layout.footer.menu3.title', {
                    // TH: ข้อมูลเพิ่มเติม
                    // EN: More information
                  })}
                </Heading>
                <LayoutFooterMenu menu={menu3}></LayoutFooterMenu>
              </Box>
              <Box width={`${100 / 4}%`}>
                <Heading
                  fontSize="md"
                  mb={3}
                  color="primary.500"
                  textTransform="uppercase"
                >
                  {t('layout.footer.menu4.title', {
                    // TH: ติดต่อเรา
                    // EN: Contact us
                  })}
                </Heading>
                {contactUsTh ? (
                  <Box
                    mb={0}
                    className="contact-us"
                    dangerouslySetInnerHTML={{ __html: contactUsTh.value }}
                  ></Box>
                ) : null}
                {contactUsEn ? (
                  <Box
                    mb={0}
                    className="contact-us"
                    dangerouslySetInnerHTML={{ __html: contactUsEn.value }}
                  ></Box>
                ) : null}
              </Box>
            </HStack>
          ) : (
            <Box
              pt={7}
              pb={{
                base: '110px',
                md: '110px',
                lg: 10,
              }}
            >
              <Accordion allowMultiple defaultIndex={[0]}>
                <AccordionItem>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton
                        color="primary.500"
                        py={1}
                        textTransform="uppercase"
                      >
                        <Box flex="1" textAlign="left">
                          {t('layout.footer.menu1.title')}
                        </Box>
                        <Center
                          boxSize="22px"
                          borderRadius="full"
                          bg="gray.100"
                        >
                          <Image
                            src={`/image-icon/${
                              isExpanded ? 'minus-sm' : 'plus-sm'
                            }.png`}
                            width={18}
                            height={18}
                            alt="next"
                          ></Image>
                        </Center>
                      </AccordionButton>
                      <AccordionPanel pb={4}>
                        <LayoutFooterMenu menu={menu1}></LayoutFooterMenu>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>

                <AccordionItem>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton
                        color="primary.500"
                        py={1}
                        textTransform="uppercase"
                      >
                        <Box flex="1" textAlign="left">
                          {t('layout.footer.menu2.title')}
                        </Box>
                        <Center
                          boxSize="22px"
                          borderRadius="full"
                          bg="gray.100"
                        >
                          <Image
                            src={`/image-icon/${
                              isExpanded ? 'minus-sm' : 'plus-sm'
                            }.png`}
                            width={18}
                            height={18}
                            alt="next"
                          ></Image>
                        </Center>
                      </AccordionButton>
                      <AccordionPanel pb={4}>
                        <LayoutFooterMenu menu={menu2}></LayoutFooterMenu>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>

                <AccordionItem>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton
                        color="primary.500"
                        py={1}
                        textTransform="uppercase"
                      >
                        <Box flex="1" textAlign="left">
                          {t('layout.footer.menu3.title')}
                        </Box>
                        <Center
                          boxSize="22px"
                          borderRadius="full"
                          bg="gray.100"
                        >
                          <Image
                            src={`/image-icon/${
                              isExpanded ? 'minus-sm' : 'plus-sm'
                            }.png`}
                            width={18}
                            height={18}
                            alt="next"
                          ></Image>
                        </Center>
                      </AccordionButton>
                      <AccordionPanel pb={4}>
                        <LayoutFooterMenu menu={menu3}></LayoutFooterMenu>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>

                <AccordionItem>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton
                        color="primary.500"
                        py={1}
                        textTransform="uppercase"
                      >
                        <Box flex="1" textAlign="left">
                          {t('layout.footer.menu4.title')}
                        </Box>
                        <Center
                          boxSize="22px"
                          borderRadius="full"
                          bg="gray.100"
                        >
                          <Image
                            src={`/image-icon/${
                              isExpanded ? 'minus-sm' : 'plus-sm'
                            }.png`}
                            width={18}
                            height={18}
                            alt="next"
                          ></Image>
                        </Center>
                      </AccordionButton>
                      <AccordionPanel pb={4}>
                        {contactUsTh ? (
                          <Box
                            mb={0}
                            className="contact-us"
                            dangerouslySetInnerHTML={{
                              __html: contactUsTh.value,
                            }}
                          ></Box>
                        ) : null}
                        {contactUsEn ? (
                          <Box
                            mb={0}
                            className="contact-us"
                            dangerouslySetInnerHTML={{
                              __html: contactUsEn.value,
                            }}
                          ></Box>
                        ) : null}
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>
            </Box>
          )}
        </Container>
      </Box>

      <Box
        position="fixed"
        bottom={currentScrollHeightPercentage < 95 ? '40px' : 350}
        right={10}
        transition="all 0.5s ease"
        display={{
          base: 'none',
          xl: 'block',
        }}
      >
        <Button
          variant="unstyled"
          alignContent="center"
          alignItems="center"
          w="60px"
          height="60px"
          as="a"
          href="https://liff.line.me/1645278921-kWRPP32q/?accountId=lamptan"
          target="_blank"
        >
          <Image
            src="/image-icon/chat-icon.png"
            width={60}
            height={60}
            alt="line"
            style={{
              margin: '0 auto',
            }}
          ></Image>
        </Button>
      </Box>

      <Box
        position="fixed"
        bottom={0}
        left="0"
        right="0"
        bg="#EADECE"
        zIndex="overlay"
        pt={4}
        pb={3}
        boxShadow="0px -1px 5px rgb(253 145 33 / 50%)"
        display={{
          base: 'block',
          lg: 'none',
        }}
        className="footer-mobile-nav"
      >
        <SimpleGrid columns={5}>
          <Button
            variant="unstyled"
            height="auto"
            onClick={() => router.push('/')}
          >
            <Center flexDirection="column">
              <Box boxSize="30px" position="relative">
                <Image
                  src="/image-icon/footer-home-icon.png"
                  fill
                  style={{
                    objectFit: 'contain',
                  }}
                  alt="home"
                ></Image>
              </Box>
              <Text
                fontSize={locale === 'th' ? '13px' : '11px'}
                fontWeight={300}
                mt={1}
                textTransform="uppercase"
              >
                {t('layout.footer.mobile.home', {
                  // TH: หน้าแรก
                  // EN: Home
                  ns: 'common',
                })}
              </Text>
            </Center>
          </Button>
          <Button
            variant="unstyled"
            height="auto"
            onClick={() => router.push('/products?type=spacial-price')}
          >
            <Center flexDirection="column">
              <Box boxSize="30px" position="relative">
                <Image
                  src="/image-icon/footer-sale-icon.png"
                  fill
                  style={{
                    objectFit: 'contain',
                  }}
                  alt="sale"
                ></Image>
              </Box>
              <Text
                fontSize={locale === 'th' ? '13px' : '11px'}
                fontWeight={300}
                mt={1}
                textTransform="uppercase"
              >
                {t('layout.footer.mobile.sale', {
                  // TH: โปรโมชั่น
                  // EN: Promotion
                  ns: 'common',
                })}
              </Text>
            </Center>
          </Button>
          <Button
            variant="unstyled"
            height="auto"
            onClick={() => router.push('/smart-home')}
          >
            <Center flexDirection="column">
              <Box boxSize="30px" position="relative">
                <Image
                  src="/image-icon/footer-smart-home-icon.png"
                  fill
                  style={{
                    objectFit: 'contain',
                  }}
                  alt="smart-home"
                ></Image>
              </Box>
              <Text
                fontSize={locale === 'th' ? '13px' : '11px'}
                fontWeight={300}
                mt={1}
                textTransform="uppercase"
              >
                {t('layout.footer.mobile.smartHome', {
                  // TH: สมาร์ทโฮม
                  // EN: Smart Home
                  ns: 'common',
                })}
              </Text>
            </Center>
          </Button>
          <Button
            variant="unstyled"
            height="auto"
            onClick={() => router.push('/decoration')}
          >
            <Center flexDirection="column">
              <Box boxSize="30px" position="relative">
                <Image
                  src="/image-icon/footer-decoration-icon.png"
                  fill
                  style={{
                    objectFit: 'contain',
                  }}
                  alt="decoration"
                ></Image>
              </Box>
              <Text
                fontSize={locale === 'th' ? '13px' : '11px'}
                fontWeight={300}
                mt={1}
                textTransform="uppercase"
              >
                {t('layout.footer.mobile.decoration', {
                  // TH: โคมไฟตกแต่ง
                  // EN: Decoration
                  ns: 'common',
                })}
              </Text>
            </Center>
          </Button>
          <Button
            variant="unstyled"
            height="auto"
            as="a"
            href="https://liff.line.me/1645278921-kWRPP32q/?accountId=lamptan"
            target="_blank"
          >
            <Center flexDirection="column">
              <Box boxSize="30px" position="relative">
                <Image
                  src="/image-icon/footer-chat-icon.png"
                  fill
                  style={{
                    objectFit: 'contain',
                  }}
                  alt="chat"
                ></Image>
              </Box>
              <Text
                fontSize={locale === 'th' ? '13px' : '11px'}
                fontWeight={300}
                mt={1}
                textTransform="uppercase"
              >
                {t('layout.footer.mobile.chat', {
                  // TH: แชท
                  // EN: Chat
                  ns: 'common',
                })}
              </Text>
            </Center>
          </Button>
        </SimpleGrid>
      </Box>
    </>
  );
}

export default LayoutFooter;
