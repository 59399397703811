import axios from 'axios';
import { IUser } from 'interfaces/user.interface';
import _axios from 'libs/axios';
import { useEffect, useMemo, useState } from 'react';
import { useUserStore } from 'store/user.store';
import useSWR from 'swr';

export default function useUser(params?: { user: IUser | null }) {
  const user = useUserStore((state) => state.user);

  const setUser = useUserStore((state) => state.setUser);

  const [loading, setLoading] = useState<boolean>(true);

  const { data: userResponse, mutate: mutateUser } =
    useSWR<IUser>('/api/users/session');

  const updateUser = async (user: IUser) => {
    setUser(user);
  };

  const logout = async () => {
    mutateUser(await axios.post('/api/users/logout-session'), false);
    setUser(null);
  };

  useEffect(() => {
    if (userResponse) {
      if (userResponse.accessToken) {
        // set axios token after login
        _axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${userResponse.accessToken}`;
      }

      setUser(userResponse);

      setLoading(false);
    }
  }, [userResponse]);

  useEffect(() => {
    if (params?.user) {
      setUser(params.user);
      setLoading(false);
    }
  }, [params?.user]);

  // get address
  const userAddresses = useMemo(() => {
    if (user && user.addresses && user.addresses.length > 0) {
      return user.addresses;
    }
    return [];
  }, [user]);

  // get primary address
  const userPrimaryAddress = useMemo(() => {
    if (userAddresses && userAddresses.length > 0) {
      return userAddresses.find((address) => address.isPrimaryAddress);
    }
    return null;
  }, [userAddresses]);

  return {
    user,
    isAuthLoading: loading,
    userAddresses,
    userPrimaryAddress,
    mutateUser,
    updateUser,
    logout,
  };
}
