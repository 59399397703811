import {
  IProduct,
  IProductVariation,
  ProductTypeEnum,
} from 'interfaces/product.interface';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

export default function useProductItem({
  product = null,
  variation = null,
}: {
  product: IProduct | null;
  variation?: IProductVariation | null;
}) {
  const router = useRouter();

  const name: string = useMemo(() => {
    if (product) {
      if (router.locale === 'en') {
        return product.name_en;
      } else {
        if (product.name_th) {
          return product.name_th;
        } else {
          return product.name_en;
        }
      }
    }

    return 'NA';
  }, [product, router]);

  const featuredImage: string = useMemo(() => {
    let _featuredImage = '/image-icon/empty-image.png';

    if (variation) {
      if (variation.featuredImage) {
        _featuredImage = `${process.env.NEXT_PUBLIC_FILE_URL}/${variation.featuredImage.name}`;
      } else if (variation.gallery && variation.gallery.length > 0) {
        if (variation.gallerySetting) {
          // find featured image
          const featuredImageFormGallery = variation.gallery.find((gallery) => {
            const gallerySetting = variation.gallerySetting?.find(
              (gallerySetting) =>
                gallerySetting.mediaId === gallery.id &&
                gallerySetting.sequence === 1
            );

            if (gallerySetting) {
              return true;
            }

            return false;
          });

          if (featuredImageFormGallery) {
            _featuredImage = `${process.env.NEXT_PUBLIC_FILE_URL}/${featuredImageFormGallery.name}`;
          } else {
            _featuredImage = `${process.env.NEXT_PUBLIC_FILE_URL}/${variation.gallery[0]?.name}`;
          }
        } else {
          _featuredImage = `${process.env.NEXT_PUBLIC_FILE_URL}/${variation.gallery[0]?.name}`;
        }
      }
    } else {
      if (product) {
        if (product.featuredImage) {
          _featuredImage = `${process.env.NEXT_PUBLIC_FILE_URL}/${product.featuredImage.name}`;
        }
      }
    }

    return _featuredImage;
  }, [product, variation]);

  const variationName: string = useMemo(() => {
    if (product && variation) {
      // if variation has attributeOptions
      if (variation.attributeOptions && variation.attributeOptions.length > 0) {
        // log variation.attributeOptions
        console.log(variation.attributeOptions);

        // get attributeOption name
        const attributeOptionNames = variation.attributeOptions.map(
          (attributeOption) => {
            if (router.locale === 'en') {
              return attributeOption.name_en;
            } else {
              if (attributeOption.name_th) {
                return attributeOption.name_th;
              } else {
                return attributeOption.name_en;
              }
            }
          }
        );

        // join attributeOption name
        return attributeOptionNames.join(' ');
      }
    }

    return '';
  }, [product, variation, router]);

  const variationNameWithAttributes: string[] = useMemo(() => {
    if (variation) {
      // if variation has attributeOptions
      if (variation.attributeOptions && variation.attributeOptions.length > 0) {
        // get attributeOption name
        const attributeOptionNames = variation.attributeOptions
          .map((attributeOption) => {
            if (attributeOption.productAttribute) {
              if (router.locale === 'en') {
                return `${attributeOption.productAttribute.name_en}: ${attributeOption.name_en}`;
              } else {
                return `${attributeOption.productAttribute.name_th}: ${attributeOption.name_th}`;
              }
            } else {
              return '';
            }
          })
          .filter((attributeOptionName) => attributeOptionName);

        return attributeOptionNames;
      }
    }

    return [];
  }, [variation, router]);

  // formate product price
  const salePrice = useMemo(() => {
    if (product) {
      const prices: number[] = [];

      if (product.customSalePrice && product.customSalePrice > 0) {
        prices.push(product.customSalePrice);
      }
      if (product.everydayPriceMin && product.everydayPriceMin > 0) {
        prices.push(product.everydayPriceMin);
      }
      if (
        product.everydayPrice &&
        product.everydayPrice > 0 &&
        product.everydayPriceAvailable
      ) {
        prices.push(product.everydayPrice);
      }
      if (product.floorPriceMin && product.floorPriceMin > 0) {
        prices.push(product.floorPriceMin);
      }
      if (
        product.floorPrice &&
        product.floorPrice > 0 &&
        product.floorPriceAvailable
      ) {
        prices.push(product.floorPrice);
      }

      // log prices
      console.log('prices', prices);

      if (prices.length > 0) {
        return Math.min(...prices);
      }

      return 0;
    }

    return 0;
  }, [product]);

  const regularPrice = useMemo(() => {
    if (product) {
      const prices: number[] = [];
      if (product.customRegularPrice && product.customRegularPrice > 0) {
        prices.push(product.customRegularPrice);
      }
      if (product.type === ProductTypeEnum.VARIATION) {
        if (product.claimedPriceMin && product.claimedPriceMin > 0) {
          prices.push(product.claimedPriceMin);
        }
      } else {
        if (product.claimedPrice && product.claimedPrice > 0) {
          prices.push(product.claimedPrice);
        }
      }

      if (prices.length > 0) {
        return Math.min(...prices);
      }

      return 0;
    }

    return 0;
  }, [product]);

  const savingPrice = useMemo(() => {
    if (regularPrice > 0 && salePrice > 0) {
      const saving = regularPrice - salePrice;
      return Math.abs(saving);
    }

    return 0;
  }, [regularPrice, salePrice]);

  return {
    product,
    featuredImage,
    name,
    variationName,
    variationNameWithAttributes,
    savingPrice,
    regularPrice,
    salePrice,
  };
}
