import {
  Box,
  Button,
  Center,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import axios from 'axios';
import useCart from 'hooks/useCart';
import useUser from 'hooks/useUser';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useWishlistStore } from 'store/wishlist.store';

function LayoutHeaderMember({}) {
  // use router
  const router = useRouter();

  const { t } = useTranslation(['common']);

  // use media query
  const [isDesktop] = useMediaQuery('(min-width: 1024px)');

  // use auth
  const { user, logout } = useUser();

  // use cart
  const { cartClear } = useCart();

  // use wish list store
  const { setWishlists, setWishlistsTotal } = useWishlistStore();

  return isDesktop ? (
    <>
      <Menu placement="bottom">
        {({ isOpen }) =>
          !user || !user.isAuthenticated ? (
            <>
              <MenuButton padding={0}>
                <HStack spacing={0} pt={1}>
                  <Center flex="0 0 auto">
                    <Image
                      src="/image-icon/user-icon-white.png"
                      width={26}
                      height={26}
                      alt="wishlist"
                    ></Image>
                  </Center>
                  <Text as="span" fontWeight={300}>
                    {t('layout.header.member.login', {
                      // TH: เข้าสู่ระบบ
                      // EN: Login
                    })}
                  </Text>
                </HStack>
              </MenuButton>
              <MenuList
                minW="150px"
                border="0"
                borderRadius={0}
                bg="transparent"
                boxShadow="none"
                pt="13px"
                color="gray.800"
              >
                <Box
                  position="relative"
                  boxShadow="0px 2px 8px rgb(0 0 0 / 30%)"
                  bg="white"
                  py={1}
                >
                  <Box
                    width="30px"
                    height="18px"
                    position="absolute"
                    top="-18px"
                    left="50%"
                    marginLeft="-15px"
                    overflow="hidden"
                    boxShadow="0 16px 10px -17px rgba(0, 0, 0, 0.5)"
                    _after={{
                      content: '""',
                      width: '15px',
                      height: '18px',
                      bg: 'white',
                      position: 'absolute',
                      transform: 'rotate(45deg)',
                      top: '10px',
                      left: '7.5px',
                      boxShadow: '-1px -1px 10px -2px rgba(0, 0, 0, 0.5)',
                    }}
                  ></Box>
                  <MenuItem
                    onClick={() => {
                      router.push('/login', '/login', {
                        locale: router.locale,
                      });
                    }}
                    justifyContent="center"
                    textAlign="center"
                    _hover={{
                      bg: 'white',
                      color: 'primary.500',
                    }}
                    _focus={{
                      bg: 'white',
                      color: 'primary.500',
                    }}
                    transition="all 0.2s"
                  >
                    {t('layout.header.member.menu.login', {
                      // TH: เข้าสู่ระบบ
                      // EN: Login
                    })}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      router.push('/register', '/register', {
                        locale: router.locale,
                      });
                    }}
                    justifyContent="center"
                    textAlign="center"
                    _hover={{
                      bg: 'white',
                      color: 'primary.500',
                    }}
                    _focus={{
                      bg: 'white',
                      color: 'primary.500',
                    }}
                    transition="all 0.2s"
                  >
                    {t('layout.header.member.menu.register', {
                      // TH: สมัครสมาชิก
                      // EN: Register
                    })}
                  </MenuItem>
                </Box>
              </MenuList>
            </>
          ) : (
            <>
              <MenuButton padding={0}>
                <HStack pt="4px" spacing={0}>
                  <Center pt={1}>
                    <Image
                      src="/image-icon/user-icon-white.png"
                      width={32}
                      height={32}
                      alt="wishlist"
                    ></Image>
                  </Center>
                  <Text fontSize="18px" maxW="100px" noOfLines={1}>
                    {user?.firstName}
                  </Text>
                </HStack>
              </MenuButton>
              <MenuList
                minW="150px"
                border="0"
                borderRadius={0}
                bg="transparent"
                boxShadow="none"
                pt="10px"
                color="gray.800"
              >
                <Box
                  position="relative"
                  boxShadow="0px 0px 6px rgb(0 0 0 / 20%)"
                  bg="white"
                  py={1}
                >
                  <Box
                    width="30px"
                    height="18px"
                    position="absolute"
                    top="-18px"
                    left="50%"
                    marginLeft="-15px"
                    overflow="hidden"
                    boxShadow="0 16px 10px -17px rgba(0, 0, 0, 0.5)"
                    _after={{
                      content: '""',
                      width: '15px',
                      height: '18px',
                      bg: 'white',
                      position: 'absolute',
                      transform: 'rotate(45deg)',
                      top: '10px',
                      left: '7.5px',
                      boxShadow: '-1px -1px 10px -2px rgba(0, 0, 0, 0.5)',
                    }}
                  ></Box>
                  <MenuItem
                    onClick={() => {
                      router.push(
                        '/my-account/details',
                        '/my-account/details',
                        {
                          locale: router.locale,
                        }
                      );
                    }}
                    justifyContent="center"
                    textAlign="center"
                    _hover={{
                      bg: 'white',
                      color: 'primary.500',
                    }}
                    _focus={{
                      bg: 'white',
                      color: 'primary.500',
                    }}
                    transition="all 0.2s"
                  >
                    {t('layout.header.member.menu.my-account', {
                      // TH: ข้อมูลของฉัน
                      // EN: My Account
                    })}
                  </MenuItem>
                  <MenuItem
                    justifyContent="center"
                    textAlign="center"
                    onClick={async () => {
                      await logout();

                      cartClear();

                      setWishlists([]);

                      setWishlistsTotal(0);

                      router.push('/', '/', {
                        locale: router.locale,
                      });
                    }}
                    _hover={{
                      bg: 'white',
                      color: 'primary.500',
                    }}
                    _focus={{
                      bg: 'white',
                      color: 'primary.500',
                    }}
                    transition="all 0.2s"
                  >
                    {t('layout.header.member.menu.logout', {
                      // TH: ออกจากระบบ
                      // EN: Logout
                    })}
                  </MenuItem>
                </Box>
              </MenuList>
            </>
          )
        }
      </Menu>
    </>
  ) : (
    <>
      <Button
        variant="unstyled"
        color="#EADED3"
        onClick={() => {
          if (user && user.isAuthenticated) {
            router.push('/my-account', '/my-account', {
              locale: router.locale,
            });
          } else {
            router.push('/login', '/login', {
              locale: router.locale,
            });
          }
        }}
      >
        <HStack>
          <Text maxW="100px" noOfLines={1}>
            {user && user.isAuthenticated
              ? user?.firstName
              : t('layout.header.member.login')}
          </Text>
          <Center>
            <Image
              src="/image-icon/user-icon-bg.png"
              width={30}
              height={30}
              alt="user"
            ></Image>
          </Center>
        </HStack>
      </Button>
    </>
  );
}

export default LayoutHeaderMember;
