import { MenuOutlined } from '@ant-design/icons';
import {
  Box,
  Button,
  Center,
  Container,
  Heading,
  HStack,
  Link,
  List,
  ListItem,
  SimpleGrid,
} from '@chakra-ui/react';
import useProductCategories from 'hooks/useProductCategories';
import useProductMainCategories from 'hooks/useProductMainCategories';
import { IProductCategory } from 'interfaces/product-category';
import Image from 'next/image';
import NextLink from 'next/link';
import { useMemo, useState } from 'react';
import LayoutHeaderCart from './LayoutHeaderCart';
import LayoutHeaderMember from './LayoutHeaderMember';
import LayoutHeaderMenuMobile from './LayoutHeaderMenuMobile';
import LayoutHeaderSearchInput from './LayoutHeaderSearchInput';
import LayoutHeaderTopbar from './LayoutHeaderTopbar';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';

function LayoutHeader() {
  const { productCategoryTree } = useProductCategories();

  const { productMainCategories } = useProductMainCategories();

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const { locale } = useRouter();

  const { t } = useTranslation(['common']);

  const categoriesMenu1 = useMemo(() => {
    if (productCategoryTree && productCategoryTree.length > 0) {
      if (
        productCategoryTree[0].children &&
        productCategoryTree[0].children.length > 0
      ) {
        return productCategoryTree[0].children.filter(
          (category, index) => index <= 12
        );
      }
    }

    return [];
  }, [productCategoryTree]);

  const categoriesMenu2 = useMemo(() => {
    if (productCategoryTree && productCategoryTree.length > 0) {
      if (
        productCategoryTree[0].children &&
        productCategoryTree[0].children.length > 0
      ) {
        return productCategoryTree[0].children.filter(
          (category, index) => index > 12 && index <= 33
        );
      }
    }

    return [];
  }, [productCategoryTree]);

  const categoriesMenu3 = useMemo(() => {
    if (productCategoryTree && productCategoryTree.length > 0) {
      if (
        productCategoryTree[0].children &&
        productCategoryTree[0].children.length > 0
      ) {
        return productCategoryTree[0].children.filter(
          (category, index) => index > 40
        );
      }
    }

    return [];
  }, [productCategoryTree]);

  const categoriesMenu4 = useMemo(() => {
    if (productCategoryTree && productCategoryTree.length > 0) {
      if (
        productCategoryTree[1].children &&
        productCategoryTree[1].children.length > 0
      ) {
        return productCategoryTree[1].children.filter(
          (category, index) => index <= 19 && category.mainCategoryId !== 3
        );
      }
    }

    return [];
  }, [productCategoryTree]);

  const categoriesMenu5 = useMemo(() => {
    if (productCategoryTree && productCategoryTree.length > 0) {
      if (
        productCategoryTree[2].children &&
        productCategoryTree[2].children.length > 0
      ) {
        return productCategoryTree[2].children.filter(
          (category, index) => index <= 3
        );
      }
    }

    return [];
  }, [productCategoryTree]);

  const categoriesMenu6 = useMemo(() => {
    if (productCategoryTree && productCategoryTree.length > 0) {
      if (
        productCategoryTree[2].children &&
        productCategoryTree[2].children.length > 0
      ) {
        return productCategoryTree[2].children.filter(
          (category, index) => index > 3
        );
      }
    }

    return [];
  }, [productCategoryTree]);

  const categoriesMenu7 = useMemo(() => {
    if (productCategoryTree && productCategoryTree.length > 0) {
      if (
        productCategoryTree[3].children &&
        productCategoryTree[3].children.length > 0
      ) {
        return productCategoryTree[3].children;
      }
    }

    return [];
  }, [productCategoryTree]);

  // log categoriesMenu1
  console.log('categoriesMenu1', categoriesMenu1);

  const renderMenu = (categories: IProductCategory[]) => {
    const menu = categories;

    return (
      <List color="gray.800" fontSize="sm" fontWeight="300">
        {menu.map((item) => (
          <ListItem key={item?.id} py="2.5px" textTransform="initial">
            <NextLink
              href={`/product-categories/${
                productMainCategories.find((m) => m.id === item.mainCategoryId)
                  ?.permalink
              }/${item?.permalink}`}
              passHref
            >
              <Link
                _hover={{
                  textDecoration: 'none',
                  color: 'primary.500',
                }}
              >
                {locale === 'th' ? item?.name_th : item?.name_en}
              </Link>
            </NextLink>
            {item?.children && (
              <List color="gray.800" fontWeight="300" ml="20px">
                {item?.children.map((child) => (
                  <ListItem key={child?.id} py="2.5px">
                    <NextLink
                      href={`/product-categories/${
                        productMainCategories.find(
                          (m) => m.id === item.mainCategoryId
                        )?.permalink
                      }/${child?.permalink}`}
                      passHref
                    >
                      <Link
                        _hover={{
                          textDecoration: 'none',
                          color: 'primary.500',
                        }}
                      >
                        {locale === 'th' ? child?.name_th : child?.name_en}
                      </Link>
                    </NextLink>
                  </ListItem>
                ))}
              </List>
            )}
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <>
      <LayoutHeaderTopbar></LayoutHeaderTopbar>

      <Box
        position="relative"
        zIndex="150"
        bg="#363437"
        height="72px"
        display={{
          base: 'none',
          lg: 'block',
        }}
      >
        <Container maxW="1160px" px={6} h="100%">
          <HStack spacing={0} color="white" h="100%" pt={1}>
            <NextLink href="/">
              <Center
                height="100%"
                pl={0}
                pr={{
                  base: 0,
                  md: 6,
                  lg: 8,
                  xl: 14,
                }}
                flex="0 0 auto"
              >
                <Image
                  src="/lamptan-logo-new.png"
                  alt="logo"
                  width={140}
                  height={30}
                ></Image>
              </Center>
            </NextLink>
            <HStack bg="#363437" height="100%" flex={1} spacing={2}>
              <Box flex={1} pr={4} height="100%">
                <List
                  display="flex"
                  justifyContent="space-around"
                  className="main-menu"
                  fontSize={{
                    xl: locale === 'th' ? 'lg' : 'md',
                  }}
                  fontWeight="400"
                  height="100%"
                  gap={4}
                >
                  <ListItem
                    className="category-menu-item"
                    height="100%"
                    display="flex"
                    alignItems="center"
                    textTransform="uppercase"
                  >
                    <NextLink href="/" passHref legacyBehavior>
                      <Link
                        _hover={{
                          textDecoration: 'none',
                        }}
                      >
                        {t('layout.header.navigation.lighting')}
                      </Link>
                    </NextLink>

                    <Box className="category-menu-dropdown" p={5}>
                      <Box
                        width="30px"
                        height="18px"
                        position="absolute"
                        top="-18px"
                        left="120px"
                        overflow="hidden"
                        boxShadow="0 16px 10px -17px rgba(0, 0, 0, 0.5)"
                        _after={{
                          content: '""',
                          width: '15px',
                          height: '18px',
                          bg: 'white',
                          position: 'absolute',
                          transform: 'rotate(45deg)',
                          top: '10px',
                          left: '7.5px',
                          boxShadow: '-1px -1px 10px -2px rgba(0, 0, 0, 0.5)',
                        }}
                      ></Box>
                      <SimpleGrid columns={4} spacing={5}>
                        <Box>
                          <Heading
                            color="primary.500"
                            borderBottom="2px solid"
                            fontSize="lg"
                            fontWeight="400"
                            mb={2}
                            pb={1}
                            textTransform="uppercase"
                          >
                            {productCategoryTree &&
                              productCategoryTree.length > 0 && (
                                <NextLink
                                  href={`/product-categories/${
                                    productMainCategories.find(
                                      (m) =>
                                        m.id ===
                                        productCategoryTree[0].mainCategoryId
                                    )?.permalink
                                  }/${productCategoryTree[0].permalink}`}
                                >
                                  {locale === 'th'
                                    ? productCategoryTree[0].name_th
                                    : productCategoryTree[0].name_en}
                                </NextLink>
                              )}
                          </Heading>
                          {renderMenu(categoriesMenu1 ? categoriesMenu1 : [])}
                        </Box>
                        <Box>
                          {renderMenu(categoriesMenu2 ? categoriesMenu2 : [])}
                        </Box>
                        <Box>
                          {/* {renderMenu(categoriesMenu3 ? categoriesMenu3 : [])} */}
                          <Heading
                            color="primary.500"
                            borderBottom="2px solid"
                            fontSize="lg"
                            fontWeight="400"
                            mb={2}
                            pb={1}
                            textTransform="uppercase"
                          >
                            {productCategoryTree &&
                              productCategoryTree.length > 0 && (
                                <NextLink
                                  href={`/product-categories/${
                                    productMainCategories.find(
                                      (m) =>
                                        m.id ===
                                        productCategoryTree[0].mainCategoryId
                                    )?.permalink
                                  }/${productCategoryTree[1].permalink}`}
                                >
                                  {locale === 'th'
                                    ? productCategoryTree[1].name_th
                                    : productCategoryTree[1].name_en}
                                </NextLink>
                              )}
                          </Heading>
                          {renderMenu(categoriesMenu4 ? categoriesMenu4 : [])}
                          <Heading
                            color="primary.500"
                            borderBottom="2px solid"
                            fontSize="lg"
                            fontWeight="400"
                            mb={2}
                            pb={1}
                            mt={2}
                            textTransform="uppercase"
                          >
                            {productCategoryTree &&
                              productCategoryTree.length > 0 && (
                                <NextLink
                                  href={`/product-categories/${
                                    productMainCategories.find(
                                      (m) =>
                                        m.id ===
                                        productCategoryTree[0].mainCategoryId
                                    )?.permalink
                                  }/${productCategoryTree[2].permalink}`}
                                >
                                  {locale === 'th'
                                    ? productCategoryTree[2].name_th
                                    : productCategoryTree[2].name_en}
                                </NextLink>
                              )}
                          </Heading>
                          {renderMenu(categoriesMenu5 ? categoriesMenu5 : [])}
                        </Box>
                        <Box>
                          {renderMenu(categoriesMenu6 ? categoriesMenu6 : [])}
                          <Heading
                            color="primary.500"
                            borderBottom="2px solid"
                            fontSize="lg"
                            fontWeight="400"
                            mb={2}
                            pb={1}
                            mt={2}
                            textTransform="uppercase"
                          >
                            {productCategoryTree &&
                              productCategoryTree.length > 0 && (
                                <NextLink
                                  href={`/product-categories/${
                                    productMainCategories.find(
                                      (m) =>
                                        m.id ===
                                        productCategoryTree[0].mainCategoryId
                                    )?.permalink
                                  }/${productCategoryTree[3].permalink}`}
                                >
                                  {locale === 'th'
                                    ? productCategoryTree[3].name_th
                                    : productCategoryTree[3].name_en}
                                </NextLink>
                              )}
                          </Heading>
                          {renderMenu(categoriesMenu7 ? categoriesMenu7 : [])}
                        </Box>
                      </SimpleGrid>
                    </Box>
                  </ListItem>
                  <ListItem
                    height="100%"
                    display="flex"
                    alignItems="center"
                    textTransform="uppercase"
                  >
                    <NextLink href="/smart-home" passHref legacyBehavior>
                      <Link
                        _hover={{
                          textDecoration: 'none',
                        }}
                      >
                        {t('layout.header.navigation.smartHome')}
                      </Link>
                    </NextLink>
                  </ListItem>
                  <ListItem
                    height="100%"
                    display="flex"
                    alignItems="center"
                    textTransform="uppercase"
                  >
                    <NextLink href="/decoration" passHref legacyBehavior>
                      <Link
                        _hover={{
                          textDecoration: 'none',
                        }}
                      >
                        {t('layout.header.navigation.decoration')}
                      </Link>
                    </NextLink>
                  </ListItem>
                </List>
              </Box>
              <Box flex="0 0 180px">
                <LayoutHeaderSearchInput />
              </Box>
              <Box flex="0 0 auto">
                <LayoutHeaderMember></LayoutHeaderMember>
              </Box>
              <Box flex="0 0 auto">
                <LayoutHeaderCart></LayoutHeaderCart>
              </Box>
            </HStack>
          </HStack>
        </Container>
      </Box>

      <Box
        display={{
          base: 'block',
          lg: 'none',
        }}
      >
        <Box bg="gray.800">
          <Container>
            <HStack py={2} justifyContent="space-between">
              <NextLink href="/">
                <Center>
                  <Image
                    src="/lamptan-logo.png"
                    alt="logo"
                    width={120}
                    height={40}
                  ></Image>
                </Center>
              </NextLink>
              <Box>
                <LayoutHeaderMember></LayoutHeaderMember>
              </Box>
            </HStack>
          </Container>
        </Box>
        <Box bg="secondary.500">
          <Container>
            <HStack py={2} spacing={3}>
              <Box flex="0 0 auto">
                <Button
                  variant="unstyled"
                  px={0}
                  py={0}
                  fontSize="2xl"
                  lineHeight={1}
                  minW="auto"
                  height="auto"
                  onClick={() => {
                    setMobileMenuOpen(true);
                  }}
                >
                  <MenuOutlined></MenuOutlined>
                </Button>
              </Box>
              <Box flex="1">
                <LayoutHeaderSearchInput />
              </Box>
              <Box flex="0 0 auto">
                <LayoutHeaderCart></LayoutHeaderCart>
              </Box>
            </HStack>
          </Container>
        </Box>
      </Box>

      <LayoutHeaderMenuMobile
        open={mobileMenuOpen}
        onClosed={() => {
          setMobileMenuOpen(false);
        }}
      ></LayoutHeaderMenuMobile>
    </>
  );
}

export default LayoutHeader;
