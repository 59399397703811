import { Box, Flex } from '@chakra-ui/react';
import LayoutFooter from 'components/layouts/footer/LayoutFooter';
import LayoutHeader from 'components/layouts/header/LayoutHeader';
import { useRouter } from 'next/router';
import React from 'react';

function RootLayout({
  children,
  showFooter = true,
  ...rest
}: {
  children: React.ReactNode;
  showFooter?: boolean;
  [key: string]: any;
}) {
  // use router
  const router = useRouter();

  const className = router.asPath.replace(/\//g, '-').replace(/^-/, '');

  return (
    <Flex flexDirection="column" {...rest} className={className}>
      <LayoutHeader />
      <Box minH="300px">{children}</Box>
      {showFooter && (
        <Box className="layout-footer-warpper">
          <LayoutFooter></LayoutFooter>
        </Box>
      )}
    </Flex>
  );
}

export default RootLayout;
