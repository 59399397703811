import { IPaginationResponse } from 'dtos/pagination.dto';
import { IProductCategory } from 'interfaces/product-category';
import useSWRImmutable from 'swr/immutable';

export default function useProductMainCategories() {
  // get all product categories
  const { data: productMainCategoriesData } = useSWRImmutable<
    IPaginationResponse<IProductCategory>
  >(
    `${process.env.NEXT_PUBLIC_API_URL}/v1/public/product-main-categories?limit=100`
  );

  return {
    productMainCategories: productMainCategoriesData?.docs || [],
  };
}
