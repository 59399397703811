import {
  Box,
  Center,
  Container,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import Image from 'next/image';
import { useRouter } from 'next/router';

function LayoutHeaderTopbar() {
  const router = useRouter();

  // convert locale to real language
  const localeFormatted = router.locale === 'th' ? 'ไทย' : 'ENGLISH';

  return (
    <Box
      bgGradient="linear-gradient(to right, #F15A38 10%, #F9A868 70%)"
      position="relative"
      zIndex="dropdown"
    >
      <Container maxW="1440" px={6}>
        <HStack justifyContent="space-between">
          <Box></Box>
          <Menu placement="bottom">
            {({ isOpen }) => (
              <>
                <MenuButton padding={0} height="30px" color="white">
                  <HStack spacing={1}>
                    <Center>
                      <Image
                        src={`/image-icon/${router.locale}-flag.png`}
                        alt="th"
                        width={18}
                        height={18}
                      ></Image>
                    </Center>
                    <Text>{localeFormatted}</Text>
                    <Center position="relative" top="1px">
                      <Image
                        src="/image-icon/dropdown-white.png"
                        width="8"
                        height="6"
                        alt="dropdown"
                      ></Image>
                    </Center>
                  </HStack>
                </MenuButton>
                <MenuList
                  minW="100px"
                  border="0"
                  boxShadow="0px 0px 6px rgb(0 0 0 / 20%)"
                  borderRadius={0}
                >
                  <Box
                    width="30px"
                    height="18px"
                    position="absolute"
                    top="-18px"
                    left="50%"
                    marginLeft="-15px"
                    overflow="hidden"
                    boxShadow="0 16px 10px -17px rgba(0, 0, 0, 0.5)"
                    _after={{
                      content: '""',
                      width: '15px',
                      height: '18px',
                      bg: 'white',
                      position: 'absolute',
                      transform: 'rotate(45deg)',
                      top: '10px',
                      left: '7.5px',
                      boxShadow: '-1px -1px 10px -2px rgba(0, 0, 0, 0.5)',
                    }}
                  ></Box>
                  <MenuItem
                    onClick={() => {
                      router.push(router.asPath, router.asPath, {
                        locale: 'th',
                      });
                    }}
                    _hover={{
                      bg: router.locale === 'th' ? 'primary.50' : 'gray.50',
                    }}
                    _focus={{
                      bg: router.locale === 'th' ? 'primary.50' : 'gray.50',
                    }}
                    backgroundColor={
                      router.locale === 'th' ? 'primary.50' : 'white'
                    }
                    isDisabled={router.locale === 'th'}
                  >
                    <HStack>
                      <Center>
                        <Image
                          src="/image-icon/th-flag.png"
                          alt="th"
                          width={20}
                          height={20}
                        ></Image>
                      </Center>
                      <Text>{router.locale === 'th' ? 'ไทย' : 'THAI'}</Text>
                    </HStack>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      router.push(router.asPath, router.asPath, {
                        locale: 'en',
                      });
                    }}
                    _hover={{
                      bg: router.locale === 'en' ? 'primary.50' : 'gray.50',
                    }}
                    _focus={{
                      bg: router.locale === 'en' ? 'primary.50' : 'gray.50',
                    }}
                    backgroundColor={
                      router.locale === 'en' ? 'primary.50' : 'white'
                    }
                    isDisabled={router.locale === 'en'}
                  >
                    <HStack>
                      <Center>
                        <Image
                          src="/image-icon/en-flag.png"
                          alt="th"
                          width={20}
                          height={20}
                        ></Image>
                      </Center>
                      <Text>ENGLISH</Text>
                    </HStack>
                  </MenuItem>
                </MenuList>
              </>
            )}
          </Menu>
        </HStack>
      </Container>
    </Box>
  );
}

export default LayoutHeaderTopbar;
