import { IAdministrator } from './admin.interface';
import { IMedia } from './media.interface';
import {
  IProductAttribute,
  IProductAttributeOption,
} from './product-attribute.interface';
import { IProductBadge } from './product-badge.interface';
import { IProductCategory, IProductMainCategory } from './product-category';
import { IShippingMethod } from './shipping-method.interface';

export enum ProductStatusEnum {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
  SCHEDULED = 'SCHEDULED',
  DELETING = 'DELETING',
}

export enum ProductTypeEnum {
  SIMPLE = 'SIMPLE',
  VARIATION = 'VARIATION',
}

export interface IProduct {
  id: number;
  name_th: string | null;
  name_en: string;
  permalink: string;
  status: ProductStatusEnum;
  type: ProductTypeEnum;
  description_th: string | null;
  description_en: string | null;
  featuredImageId: number | null;
  sku: string | null;
  salePrice: number | null;
  regularPrice: number | null;
  claimedPrice: number | null;
  claimedPriceAvailable: boolean;
  claimedPriceMin: number | null;
  claimedPriceMax: number | null;
  everydayPrice: number | null;
  everydayPriceAvailable: boolean;
  everydayPriceMin: number | null;
  everydayPriceMax: number | null;
  floorPrice: number | null;
  floorPriceAvailable: boolean;
  floorPriceMin: number | null;
  floorPriceMax: number | null;
  stockQuantity: number | null;
  stockQuantityTotal: number | null;
  weight: number | null;
  dimensionLength: number | null;
  dimensionWidth: number | null;
  dimensionHeight: number | null;
  sequence: number;
  brochure: string | null;
  manualGuide: string | null;
  videoUrl: string | null;
  createdById: number | null;
  updatedById: number | null;
  publishedAt: Date | null;
  publishedSchedule: Date | null;
  categoriesIds: number[] | number[][];
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  deletedById: number | null;
  allowShipping: boolean;
  customRegularPrice: number | null;
  customSalePrice: number | null;
  isBestSeller: boolean;
  isNewArrival: boolean;
  isOnSale: boolean;
  isBuy1Get1: boolean;
  productBadgeId: number | null;
  gallerySetting: IProductGalleryProperty[];

  createdBy?: IAdministrator;
  updatedBy?: IAdministrator;
  deletedBy?: IAdministrator;
  featuredImage?: IMedia;
  gallery?: IMedia[];
  categories: IProductCategory[];
  variations?: IProductVariation[];
  mainCategory: IProductMainCategory;
  tags?: IProductTag[];
  attributes?: IProductAttribute[];
  attributeOrders?: IProductProductAttributeOrders[];
  attributeOptions?: IProductAttributeOption[];
  breadcrumbCategories: IProductCategory[];
  shippingMethods?: IShippingMethod[];
  productBadge?: IProductBadge;
}

export interface IProductProductAttributeFormatted extends IProductAttribute {
  options: IProductAttributeOption[];
}

export interface IProductProductAttributeOrders {
  attributeId: number;
  sequence: number;
}

export interface IProductGalleryProperty {
  mediaId: number;
  sequence: number;
}

export interface IProductVariation {
  id: number;
  productId: number;
  sku: string | null;
  description_th: string | null;
  description_en: string | null;
  price: number;
  salePrice: number | null;
  regularPrice: number | null;
  claimedPrice: number | null;
  claimedPriceAvailable: boolean;
  everydayPrice: number | null;
  everydayPriceAvailable: boolean;
  floorPrice: number | null;
  floorPriceAvailable: boolean;
  stockQuantity: number | null;
  weight: number | null;
  dimensionLength: number | null;
  dimensionWidth: number | null;
  dimensionHeight: number | null;
  sequence: string;
  visibility: boolean;
  allowShipping: boolean;
  createdById: number | null;
  updatedById: number | null;
  createdAt: Date;
  updatedAt: Date;
  gallerySetting?: IProductGalleryProperty[];

  createdBy?: IAdministrator | null;
  updatedBy?: IAdministrator | null;
  attributeOptions?: IProductAttributeOption[] | null;
  gallery?: IMedia[] | null;
  featuredImage?: IMedia | null;
  product?: IProduct | null;
}

export interface IProductTag {
  id: number;
  name: string;
  permalink: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface IProductStatusesCount {
  DRAFT: number;
  PUBLISHED: number;
  INACTIVE: number;
  PENDING: number;
  SCHEDULED: number;
  DELETING: number;
}

export interface IProductPopular {
  pagePath: string;
  slug: string;
  screenPageViews: string;
  product: IProduct;
}
