import { IThailandProvince } from './provice.interface';

export interface IUser {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  birthDate: string | null;
  mobileNumber: string | null;
  gender: UserGenderEnum;
  status: UserStatusEnum;
  passwordResetToken: string | null;
  passwordIsSet: boolean;
  createdAt: Date;
  updatedAt: Date;

  addresses?: IUserAddress[];

  accessToken: string; // this is for client side only
  isAuthenticated: boolean; // this is for client side only
}

export enum UserStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SUSPENDED = 'SUSPENDED',
}

export enum UserGenderEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

export enum UserTaxInvoiceTypeEnum {
  INDIVIDUAL = 'INDIVIDUAL',
  COMPANY = 'COMPANY',
}

export interface IUserAddress {
  id: number;
  userId: number;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  address: string;
  street: string | null;
  subDistrict: string;
  district: string;
  provinceId: number;
  postalCode: string;
  country: string;
  taxInvoiceType: UserTaxInvoiceTypeEnum | null;
  taxInvoiceId: string | null;
  taxInvoiceCompanyName: string | null;
  taxInvoiceCompanyBranch: string | null;
  isPrimaryAddress: boolean;
  createdAt: Date;
  updatedAt: Date;

  hasTaxInvoice?: boolean;
  province?: IThailandProvince;
}
