import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  HStack,
  Input,
  useNumberInput,
  UseNumberInputProps,
} from '@chakra-ui/react';

type InputNumberProps = {
  size?: 'xs' | 'sm' | 'md' | 'lg';
};

function InputNumber({
  size = 'md',
  ...rest
}: UseNumberInputProps & InputNumberProps) {
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput(rest);

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  return (
    <HStack spacing={0}>
      <Button
        {...dec}
        variant="outline"
        colorScheme="gray"
        color="gray.500"
        minW={size === 'xs' ? '24px' : size === 'sm' ? '24px' : '32px'}
        p={size === 'xs' ? '0 4px' : size === 'sm' ? '0 6px' : '0 8px'}
        h={size === 'xs' ? '20px' : size === 'sm' ? '24px' : '32px'}
        fontSize={size === 'xs' ? '10px' : size === 'sm' ? '12px' : '14px'}
        _hover={{ bg: 'gray.50' }}
        _disabled={{ opacity: 1, cursor: 'not-allowed' }}
      >
        <MinusOutlined></MinusOutlined>
      </Button>
      <Input
        {...input}
        borderLeft={0}
        borderRight={0}
        outline="none !important"
        boxShadow="none !important"
        _focus={{ boxShadow: 'none !important' }}
        textAlign="center"
        h={size === 'xs' ? '20px' : size === 'sm' ? '24px' : '32px'}
        fontSize={size === 'xs' ? '12px' : size === 'sm' ? '14px' : '16px'}
        px={0}
        minW="25px"
      />
      <Button
        {...inc}
        variant="outline"
        colorScheme="gray"
        color="gray.500"
        minW={size === 'xs' ? '24px' : size === 'sm' ? '24px' : '32px'}
        p={size === 'xs' ? '0 4px' : size === 'sm' ? '0 6px' : '0 8px'}
        h={size === 'xs' ? '20px' : size === 'sm' ? '24px' : '32px'}
        fontSize={size === 'xs' ? '10px' : size === 'sm' ? '12px' : '14px'}
        _hover={{ bg: 'gray.50' }}
        _disabled={{ opacity: 1, cursor: 'not-allowed' }}
      >
        <PlusOutlined></PlusOutlined>
      </Button>
    </HStack>
  );
}

export default InputNumber;
