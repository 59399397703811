import { IPaginationResponse } from 'dtos/pagination.dto';
import { IShippingMethod } from 'interfaces/shipping-method.interface';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import useSWR from 'swr';

export default function useShop() {
  const { data: shippingMethodsData } = useSWR<
    IPaginationResponse<IShippingMethod>
  >(`${process.env.NEXT_PUBLIC_API_URL}/v1/public/shop/shipping-methods`);

  // get available shipping methods
  const shippingMethodAvailable: IShippingMethod[] = useMemo(() => {
    // ** NOTE: This is a fixed shipping method for first release
    // ** allow only EMS and REGISTERED
    if (shippingMethodsData) {
      return shippingMethodsData.docs
        .filter(
          (method) => method.active && (method.id === 1 || method.id === 3) // 1: EMS 3: REGISTERED
        )
        .map((method) => {
          return {
            ...method,
            name_en:
              method.id === 1 ? 'EMS' : method.id === 3 ? 'REGISTER' : '',
            name_th:
              method.id === 1 ? 'EMS' : method.id === 3 ? 'ลงทะเบียน' : '',
          };
        })
        .sort((a, b) => b.id - a.id);
    }
    return [];
  }, [shippingMethodsData]);

  return {
    shippingMethods: shippingMethodsData ? shippingMethodsData.docs : [],
    shippingMethodAvailable,
  };
}
