import {
  ICartItemCreateDto,
  ICartItemQuantityUpdateDto,
  ICartUpdateBillingAddressDto,
} from 'dtos/cart.dto';
import { ICart, ICartItem } from 'interfaces/cart.interface';
import _axios from 'libs/axios';

export class CartRepository {
  constructor() {}

  static findOne() {
    return new Promise<ICart>(async (resolve, reject) => {
      try {
        const { data } = await _axios.get<ICart>('/v1/public/cart');
        return resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  static addItems(body: ICartItemCreateDto[]) {
    return new Promise<ICart>(async (resolve, reject) => {
      try {
        const { data } = await _axios.post<ICart>(
          `/v1/public/cart/add-items`,
          body
        );
        return resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  static addOrUpdateItemToCart(body: ICartItemCreateDto) {
    return new Promise<ICart>(async (resolve, reject) => {
      try {
        const { data } = await _axios.patch<ICart>(
          `/v1/public/cart/add-item`,
          body
        );
        return resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  static removeItem(key: string) {
    return new Promise<ICart>(async (resolve, reject) => {
      try {
        const { data } = await _axios.delete<ICart>(
          `/v1/public/cart/remove-item/${key}`
        );
        return resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  static updateQuantity(body: ICartItemQuantityUpdateDto) {
    return new Promise<ICartItem>(async (resolve, reject) => {
      try {
        const { data } = await _axios.patch<ICartItem>(
          `/v1/public/cart/update-item-quantity`,
          body
        );
        return resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  static itemSelectUpdate(key: string, isSelected: boolean) {
    return new Promise<ICart>(async (resolve, reject) => {
      try {
        const { data } = await _axios.patch<ICart>(
          `/v1/public/cart/items/${key}/select`,
          {
            selected: isSelected,
          }
        );
        return resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  static updateShippingMethod(shippingMethodId: number) {
    return new Promise<ICart>(async (resolve, reject) => {
      try {
        const { data } = await _axios.patch<ICart>(
          `/v1/public/cart/shipping-methods/${shippingMethodId}`
        );
        return resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  static updateShippingAddress(addressId: number) {
    return new Promise<ICart>(async (resolve, reject) => {
      try {
        const { data } = await _axios.patch<ICart>(
          `/v1/public/cart/shipping-address/${addressId}`
        );
        return resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  static updateBillingAddress(body: ICartUpdateBillingAddressDto) {
    return new Promise<ICart>(async (resolve, reject) => {
      try {
        const { data } = await _axios.patch<ICart>(
          `/v1/public/cart/billing-address`,
          body
        );
        return resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  static validateBeforeCheckout() {
    return new Promise<ICart>(async (resolve, reject) => {
      try {
        const { data } = await _axios.post<ICart>(`/v1/public/cart`);
        return resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  static applyCoupon(code: string) {
    return new Promise<ICart>(async (resolve, reject) => {
      try {
        const { data } = await _axios.post<ICart>(`/v1/public/coupon/apply`, {
          code,
        });
        return resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  static removeCoupon(id: number) {
    return new Promise<ICart>(async (resolve, reject) => {
      try {
        const { data } = await _axios.post<ICart>(
          `/v1/public/coupon/${id}/remove`
        );
        return resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  static validateCoupon() {
    return new Promise<ICart>(async (resolve, reject) => {
      try {
        const { data } = await _axios.post<ICart>(`/v1/public/coupon/validate`);
        return resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }
}
