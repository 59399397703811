import { Box, Link, Stack } from '@chakra-ui/react';
import React from 'react';
import NexLink from 'next/link';

export interface ILayoutFooterMenuItem {
  label: string;
  href: string;
  target?: string;
}

type LayoutFooterMenuProps = {
  menu: ILayoutFooterMenuItem[];
};

function LayoutFooterMenu({ menu }: LayoutFooterMenuProps) {
  return (
    <Stack spacing={1}>
      {menu.map((item, index) => {
        return (
          <Box key={index}>
            <NexLink href={item.href} passHref target={item.target ?? '_self'}>
              {item.label}
            </NexLink>
          </Box>
        );
      })}
    </Stack>
  );
}

export default LayoutFooterMenu;
