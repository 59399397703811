import { IWishlist } from 'interfaces/wishlist.interface';
import create from 'zustand';

interface WishListStoreState {
  wishlists: IWishlist[];
  wishlistsTotal: number;
  createWishlist: (payload: IWishlist) => void;
  addWishlist: (wishlist: IWishlist) => void;
  deleteWishlist: (id: number) => void;
  setWishlists: (wishlists: IWishlist[]) => void;
  setWishlistsTotal: (total: number) => void;
}

export const useWishlistStore = create<WishListStoreState>((set) => ({
  wishlists: [],
  wishlistsTotal: 0,
  createWishlist: (payload) => set(() => ({ wishlists: [payload] })),
  addWishlist: (payload) =>
    set((state) => ({ wishlists: [...state.wishlists, payload] })),
  deleteWishlist: (id) =>
    set((state) => ({
      wishlists: state.wishlists.filter((wishlist) => wishlist.id !== id),
    })),
  setWishlists: (payload) => set(() => ({ wishlists: payload })),
  setWishlistsTotal: (payload) => set(() => ({ wishlistsTotal: payload })),
}));
