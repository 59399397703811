import { IPaginationResponse } from 'dtos/pagination.dto';
import { IProductCategory } from 'interfaces/product-category';
import useSWR from 'swr';

export default function useProductCategories(includeMainCategories = false) {
  // get all product categories
  const { data: productCategoriesData, error: isProductCategoriesError } =
    useSWR<IPaginationResponse<IProductCategory>>(
      `${
        process.env.NEXT_PUBLIC_API_URL
      }/v1/public/product-categories?limit=500${
        includeMainCategories ? '&include=mainCategory' : ''
      }`,
      {
        revalidateOnFocus: false,
        refreshInterval: 1000 * 60 * 5, // 5 minutes
      }
    );

  // get product categories tree
  const { data: productCategoriesTreeData, error: productCategoriesTreeError } =
    useSWR<IProductCategory[]>(
      `${process.env.NEXT_PUBLIC_API_URL}/v1/public/product-categories/tree`,
      {
        revalidateOnFocus: false,
        refreshInterval: 1000 * 60 * 5, // 5 minutes
      }
    );

  return {
    productCategoriesLoading:
      !productCategoriesData && !isProductCategoriesError,
    productCategories: productCategoriesData?.docs || [],
    productCategoryTreeLoading:
      !productCategoriesTreeData && !productCategoriesTreeError,
    productCategoryTree: productCategoriesTreeData || [],
  };
}
