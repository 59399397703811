import {
  Box,
  Button,
  Center,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  useMediaQuery,
} from '@chakra-ui/react';
import MiniCart from 'components/carts/MiniCart';
import useCart from 'hooks/useCart';
import useWishlist from 'hooks/useWishlist';
import Image from 'next/image';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';

function LayoutHeaderCart({}) {
  // use router
  const router = useRouter();

  // use cart
  const { cart } = useCart();

  // const use wish list
  const { wishlistsTotal } = useWishlist();

  // use media query
  const [isDesktop] = useMediaQuery('(min-width: 992px)');

  // is allow show cart
  const isAllowShowCart = useMemo(() => {
    return (
      cart &&
      cart.items?.length > 0 &&
      router.pathname !== '/cart' &&
      router.pathname !== '/checkout' &&
      router.pathname !== '/checkout/payment'
    );
  }, [cart, router]);

  // log cart
  useEffect(() => {
    console.log('cart', cart);
  }, [cart]);

  const cartTotalQuantity = cart
    ? cart.items
        ?.filter((item) => item.selected)
        .reduce((acc, item) => acc + item.quantity, 0)
    : 0;

  // log cartTotalQuantity
  useEffect(() => {
    console.log('cartTotalQuantity', cartTotalQuantity);
  }, [cartTotalQuantity]);

  return (
    <HStack spacing={2}>
      <NextLink href="/my-account/wishlists" passHref legacyBehavior>
        <Button
          variant="link"
          as="a"
          height="30px"
          minW="20px"
          position="relative"
        >
          {wishlistsTotal > 0 && (
            <Center
              top="-2px"
              right="-4px"
              position="absolute"
              bg="red"
              color="white"
              width="16px"
              height="16px"
              borderRadius="full"
              fontSize={10}
              zIndex={1}
            >
              {wishlistsTotal >= 10 ? '9+' : wishlistsTotal}
            </Center>
          )}
          <Image
            src={`/image-icon/${
              isDesktop ? 'wishlist-active.png' : 'wishlist.png'
            }`}
            width={25}
            height={24}
            alt="wishlist"
          ></Image>
        </Button>
      </NextLink>
      {isAllowShowCart && isDesktop ? (
        <Menu placement="bottom-end">
          {({ isOpen }) => (
            <>
              <MenuButton height="30px" minW="20px" position="relative">
                <Image
                  src={`/image-icon/${
                    isDesktop ? 'shopping-bag-white.png' : 'shopping-bag.png'
                  }`}
                  width={20}
                  height={24}
                  alt="cart"
                ></Image>
                {cartTotalQuantity > 0 && (
                  <Center
                    top="-1px"
                    right="-11px"
                    position="absolute"
                    bg="red"
                    color="white"
                    width="16px"
                    height="16px"
                    borderRadius="full"
                    fontSize={10}
                    zIndex={1}
                  >
                    {cartTotalQuantity}
                  </Center>
                )}
              </MenuButton>
              <MenuList
                minW="150px"
                border="0"
                borderRadius={0}
                bg="transparent"
                boxShadow="none"
                pt="10px"
                mr="-25px"
                color="gray.800"
                w="320px"
              >
                <Box
                  position="relative"
                  boxShadow="0px 2px 8px rgb(0 0 0 / 30%)"
                  bg="white"
                  py={1}
                >
                  <Box
                    width="30px"
                    height="18px"
                    position="absolute"
                    top="-18px"
                    right="21px"
                    overflow="hidden"
                    boxShadow="0 16px 10px -17px rgba(0, 0, 0, 0.5)"
                    _after={{
                      content: '""',
                      width: '15px',
                      height: '18px',
                      bg: 'white',
                      position: 'absolute',
                      transform: 'rotate(45deg)',
                      top: '10px',
                      left: '7.5px',
                      boxShadow: '-1px -1px 10px -2px rgba(0, 0, 0, 0.5)',
                    }}
                  ></Box>
                  <MiniCart></MiniCart>
                </Box>
              </MenuList>
            </>
          )}
        </Menu>
      ) : (
        <NextLink href="/cart" passHref legacyBehavior>
          <Button
            variant="link"
            as="a"
            height="30px"
            minW="20px"
            position="relative"
          >
            {cartTotalQuantity > 0 && (
              <Center
                top="-1px"
                right="-11px"
                position="absolute"
                bg="red"
                color="white"
                width="16px"
                height="16px"
                borderRadius="full"
                fontSize={10}
                zIndex={1}
              >
                {cartTotalQuantity}
              </Center>
            )}
            <Image
              src={`/image-icon/${
                isDesktop ? 'shopping-bag-white.png' : 'shopping-bag.png'
              }`}
              width={20}
              height={24}
              alt="cart"
            ></Image>
          </Button>
        </NextLink>
      )}
    </HStack>
  );
}

export default LayoutHeaderCart;
