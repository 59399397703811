import { notification } from 'antd';

function useCatchError() {
  const errorMessage = (error: any) => {
    if (error) {
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log('error.response.data', error.response.data);

        const _message = Array.isArray(error.response.data?.message)
          ? error.response.data?.message[0]
          : error.response.data?.message;

        let messageFormatted = _message;

        notification.error({
          message: 'พบข้อผิดพลาด',
          description: messageFormatted,
        });
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);

        notification.error({
          message: 'พบข้อผิดพลาด',
          description: error.message,
        });
      } else {
        console.log('error', error);

        let messageFormatted = error.message;

        if (error.message === 'invalid base-58 value') {
          messageFormatted = 'You private key is invalid';
        }

        notification.error({
          message: 'พบข้อผิดพลาด',
          description: error.message,
        });
      }
    }
  };

  return errorMessage;
}

export default useCatchError;
