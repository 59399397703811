import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  HStack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import useProductCategoriesArchive from 'hooks/useProductCategoriesArchive';
import useProductMainCategories from 'hooks/useProductMainCategories';
import {
  IProductCategory,
  IProductMainCategory,
} from 'interfaces/product-category';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

type LayoutHeaderMenuMobileProps = {
  open: boolean;
  onClosed: () => void;
};

function LayoutHeaderMenuMobile({
  open,
  onClosed,
}: LayoutHeaderMenuMobileProps) {
  const { t } = useTranslation(['common']);

  const { isOpen, onOpen, onClose } = useDisclosure({
    isOpen: open,
  });
  const { productMainCategories } = useProductMainCategories();

  const {
    productCategories,
    productCategoryTree,
    productCategoriesTreeForLighting,
    productCategoriesTreeForSmartHome,
    productCategoriesTreeForDecorationLampStyle,
    productCategoriesTreeForDecorationLampFunction,
    currentParentCategoryIndexes,
    productCategoriesSelectedIds,
    handleCategorySelected,
    handleSetCurrentParentCategoryIndexes,
    handleClearFilter,
  } = useProductCategoriesArchive();

  const router = useRouter();

  const { locale } = router;

  // curent child category
  const [currentChildCategory, setCurrentChildCategory] = React.useState<
    IProductCategory[]
  >([]);

  // prev child category
  const [prevChildCategory, setPrevChildCategory] = React.useState<
    IProductCategory[]
  >([]);

  // current menu item
  const [currentMenuItem, setCurrentMenuItem] = React.useState<
    IProductCategory[]
  >([]);

  // predefined decoration lamp top level category
  const decorationLampTopLevelCategory: any[] = [
    {
      id: 1000,
      name_th: 'สไตล์',
      name_en: 'Style',
      parentId: null,
      mainCategoryId: 3,
      mainCategory: productMainCategories.find((m) => m.id === 3),
      permalink: '/decoration-lamp/?productType=decoration',
      sequence: '01',
      createdAt: new Date(),
      updatedAt: new Date(),
      children: productCategoriesTreeForDecorationLampStyle,
    },
    {
      id: 1001,
      name_th: 'การใช้งาน',
      name_en: 'Function',
      parentId: null,
      mainCategoryId: 3,
      mainCategory: productMainCategories.find((m) => m.id === 3),
      permalink: '/decoration-lamp/?productType=decoration',
      sequence: '02',
      createdAt: new Date(),
      updatedAt: new Date(),
      children: productCategoriesTreeForDecorationLampFunction,
    },
  ];

  // handle parent category click
  const handleParentCategoryClick = (
    category: IProductCategory | IProductMainCategory
  ) => {
    // log category
    console.log('handleParentCategoryClick', category);

    if (category.permalink === 'lighting') {
      const topLevelChildCategory = productCategoriesTreeForLighting;

      // log topLevelChildCategory
      console.log('topLevelChildCategory', topLevelChildCategory);

      if (topLevelChildCategory && topLevelChildCategory.length > 0) {
        setCurrentChildCategory(topLevelChildCategory);
      }
    } else if (category.permalink === 'smart-home') {
      const topLevelChildCategory = productCategoriesTreeForSmartHome;

      // log topLevelChildCategory
      console.log('topLevelChildCategory', topLevelChildCategory);

      if (topLevelChildCategory && topLevelChildCategory.length > 0) {
        setCurrentChildCategory(topLevelChildCategory);
      }
    } else if (category.permalink === 'decoration-lamp') {
      const topLevelChildCategory = decorationLampTopLevelCategory;

      // log topLevelChildCategory
      console.log('topLevelChildCategory', topLevelChildCategory);

      if (topLevelChildCategory && topLevelChildCategory.length > 0) {
        setCurrentChildCategory(topLevelChildCategory);
      }
    }
  };

  const handleChildCategoryClick = useCallback(
    (category: IProductCategory) => {
      if (category.children && category.children.length > 0) {
        setCurrentChildCategory(category.children);
        setPrevChildCategory(currentChildCategory);
        setCurrentMenuItem([...currentMenuItem, category]);
      } else {
        // close drawer
        onClose();
        onClosed();

        router.push(
          `/product-categories/${
            productMainCategories.find((m) => m.id === category.mainCategoryId)
              ?.permalink
          }/${category?.permalink}`
        );
      }
    },
    [currentChildCategory, onClosed, productMainCategories, router]
  );

  const handleClose = () => {
    onClose();
    onClosed();

    // reset current child category
    setCurrentChildCategory([]);

    // reset prev child category
    setPrevChildCategory([]);

    // reset current menu item
    setCurrentMenuItem([]);
  };

  console.log('currentChildCategory', currentChildCategory);

  console.log('prevChildCategory', prevChildCategory);

  // log setCurrentMenuItem
  console.log('currentMenuItem', currentMenuItem);

  const lastCurrentMenuItem = currentMenuItem[currentMenuItem.length - 1];

  // log lastCurrentMenuItem
  console.log('lastCurrentMenuItem', lastCurrentMenuItem);

  return (
    <Drawer isOpen={isOpen} placement="left" onClose={handleClose}>
      <DrawerOverlay />
      <DrawerContent maxW="85%">
        <DrawerBody bg="#EADECE" p={0}>
          <HStack bg="#000" py={3} px={5}>
            <Box>
              <Image
                src="/lamptan-logo.png"
                alt="logo"
                width={140}
                height={30}
              ></Image>
            </Box>
            <DrawerCloseButton color="white" />
          </HStack>

          {currentChildCategory && currentChildCategory.length > 0 ? (
            <>
              <Button
                variant="unstyled"
                width="100%"
                height="auto"
                onClick={() => {
                  // log currentChildCategory
                  console.log('currentChildCategory', currentChildCategory);

                  // log prevChildCategory
                  console.log('prevChildCategory', prevChildCategory);

                  currentMenuItem.pop();

                  // log currentMenuItem
                  console.log('currentMenuItem', currentMenuItem);

                  if (lastCurrentMenuItem && lastCurrentMenuItem.id === 1) {
                    const topLevelChildCategory = productCategories.filter(
                      (c) => c.mainCategoryId === 1 && !c.parentId
                    );

                    // log topLevelChildCategory
                    console.log('topLevelChildCategory', topLevelChildCategory);
                    if (productCategoriesTreeForLighting) {
                      setCurrentChildCategory(productCategoriesTreeForLighting);
                    }
                  } else {
                    if (
                      !currentChildCategory[0].parentId &&
                      prevChildCategory
                    ) {
                      setCurrentChildCategory([]);
                      setPrevChildCategory([]);
                    } else if (
                      currentChildCategory &&
                      currentChildCategory.length > 0 &&
                      prevChildCategory &&
                      prevChildCategory.length > 0 &&
                      currentChildCategory.length ===
                        prevChildCategory.length &&
                      currentChildCategory[0].parentId ===
                        prevChildCategory[0].parentId
                    ) {
                      setCurrentChildCategory([]);
                      setPrevChildCategory([]);
                    } else if (
                      prevChildCategory &&
                      prevChildCategory.length > 0
                    ) {
                      setCurrentChildCategory(prevChildCategory);
                    } else {
                      setCurrentChildCategory([]);
                      setPrevChildCategory([]);
                    }
                  }
                }}
              >
                <HStack borderBottom="1px solid #cdcdcd" py={3} px={5}>
                  <Center borderRadius="full" bg="white" boxSize="30px">
                    <LeftOutlined />
                  </Center>
                  <HStack>
                    {currentChildCategory &&
                      currentChildCategory[0].mainCategory && (
                        <Center flex="0 0 30px" w="30px">
                          <Image
                            src={
                              currentChildCategory[0].mainCategory.id === 1
                                ? '/image-icon/lighting-icon.png'
                                : currentChildCategory[0].mainCategory.id === 2
                                ? '/image-icon/smart-home-icon.png'
                                : '/image-icon/decoration-icon.png'
                            }
                            width={
                              currentChildCategory[0].mainCategory.id === 1
                                ? 18
                                : currentChildCategory[0].mainCategory?.id === 2
                                ? 26
                                : 22
                            }
                            height={30}
                            alt="icon"
                          ></Image>
                        </Center>
                      )}
                    <Box textTransform="uppercase">
                      {lastCurrentMenuItem ? (
                        <>
                          {locale === 'th'
                            ? lastCurrentMenuItem.name_th
                            : lastCurrentMenuItem.name_en &&
                              lastCurrentMenuItem.name_en === 'Lighting'
                            ? 'Lighting & Electric Devices'
                            : lastCurrentMenuItem.name_en === 'Smart Home'
                            ? 'Smart Home Solution'
                            : lastCurrentMenuItem.name_en}
                        </>
                      ) : currentChildCategory &&
                        currentChildCategory[0] &&
                        prevChildCategory &&
                        prevChildCategory.length > 0 ? (
                        <Text textTransform="uppercase">
                          {prevChildCategory.find(
                            (c) => c.id === currentChildCategory[0].parentId
                          )?.name_th ? (
                            <>
                              {locale === 'th'
                                ? prevChildCategory.find(
                                    (c) =>
                                      c.id === currentChildCategory[0].parentId
                                  )?.name_th
                                : prevChildCategory.find(
                                    (c) =>
                                      c.id === currentChildCategory[0].parentId
                                  )?.name_en &&
                                  prevChildCategory.find(
                                    (c) =>
                                      c.id === currentChildCategory[0].parentId
                                  )?.name_en === 'Lighting'
                                ? 'Lighting & Electric Devices'
                                : prevChildCategory.find(
                                    (c) =>
                                      c.id === currentChildCategory[0].parentId
                                  )?.name_en === 'Smart Home'
                                ? 'Smart Home Solution'
                                : prevChildCategory.find(
                                    (c) =>
                                      c.id === currentChildCategory[0].parentId
                                  )?.name_en}
                            </>
                          ) : (
                            <>
                              {locale === 'th'
                                ? productMainCategories.find(
                                    (m) =>
                                      m.id ===
                                      currentChildCategory[0].mainCategoryId
                                  )?.name_th
                                : productMainCategories.find(
                                    (m) =>
                                      m.id ===
                                      currentChildCategory[0].mainCategoryId
                                  )?.name_en &&
                                  productMainCategories.find(
                                    (m) =>
                                      m.id ===
                                      currentChildCategory[0].mainCategoryId
                                  )?.name_en === 'Lighting'
                                ? 'Lighting & Electric Devices'
                                : productMainCategories.find(
                                    (m) =>
                                      m.id ===
                                      currentChildCategory[0].mainCategoryId
                                  )?.name_en === 'Smart Home'
                                ? 'Smart Home Solution'
                                : productMainCategories.find(
                                    (m) =>
                                      m.id ===
                                      currentChildCategory[0].mainCategoryId
                                  )?.name_en}
                            </>
                          )}
                        </Text>
                      ) : (
                        <Text textTransform="uppercase">
                          {locale === 'th'
                            ? currentChildCategory &&
                              currentChildCategory[0].mainCategory?.name_th
                            : currentChildCategory &&
                              currentChildCategory[0].mainCategory?.name_en ===
                                'Lighting'
                            ? 'Lighting & Electric Devices'
                            : currentChildCategory &&
                              currentChildCategory[0].mainCategory?.name_en ===
                                'Smart Home'
                            ? 'Smart Home Solution'
                            : currentChildCategory &&
                              currentChildCategory[0].mainCategory?.name_en &&
                              currentChildCategory[0].mainCategory?.name_en}
                        </Text>
                      )}
                    </Box>
                  </HStack>
                </HStack>
              </Button>

              <Button
                variant="unstyled"
                width="100%"
                height="auto"
                onClick={() => {
                  if (lastCurrentMenuItem) {
                    if (lastCurrentMenuItem.mainCategory?.permalink) {
                      router.push(
                        `/product-categories/${lastCurrentMenuItem.mainCategory?.permalink}/${lastCurrentMenuItem?.permalink}`
                      );
                    } else {
                      router.push(
                        `/product-categories/${lastCurrentMenuItem?.permalink}`
                      );
                    }
                  } else {
                    if (currentChildCategory[0]) {
                      if (currentChildCategory[0].mainCategory) {
                        if (!currentChildCategory[0].parentId) {
                          router.push(
                            `/product-categories/${currentChildCategory[0].mainCategory?.permalink}`
                          );
                        } else if (
                          currentChildCategory[0].mainCategoryId === 2
                        ) {
                          // smart home
                          router.push(
                            `/product-categories/${currentChildCategory[0].mainCategory.permalink}`
                          );
                        } else if (
                          currentChildCategory[0].mainCategoryId === 3
                        ) {
                          // decoration
                          router.push(
                            `/product-categories/${currentChildCategory[0].mainCategory.permalink}`
                          );
                        } else {
                          router.push(
                            `/product-categories/${currentChildCategory[0].mainCategory.permalink}/${currentChildCategory[0].permalink}`
                          );
                        }
                      } else {
                        router.push(
                          `/product-categories/${currentChildCategory[0]?.permalink}`
                        );
                      }
                    } else {
                      router.push('/product-categories');
                    }
                  }

                  onClose();
                  onClosed();
                }}
              >
                <HStack
                  borderBottom="1px solid #cdcdcd"
                  py={3}
                  px={5}
                  justifyContent="space-between"
                >
                  <Box
                    textTransform={
                      currentChildCategory.length > 0 &&
                      currentChildCategory[0].parentId
                        ? 'capitalize'
                        : 'uppercase'
                    }
                  >
                    {t('layout.header.mobileMenu.all')}
                  </Box>
                </HStack>
              </Button>

              {currentChildCategory.map((category, index) => {
                return (
                  <Button
                    variant="unstyled"
                    key={index}
                    width="100%"
                    height="auto"
                    onClick={() => {
                      handleChildCategoryClick(category);
                    }}
                  >
                    <HStack
                      borderBottom="1px solid #cdcdcd"
                      key={index}
                      py={3}
                      px={5}
                      justifyContent="space-between"
                    >
                      <Box
                        textTransform={
                          currentChildCategory.length > 0 &&
                          currentChildCategory[0].parentId
                            ? 'capitalize'
                            : 'uppercase'
                        }
                      >
                        {locale === 'th' ? category.name_th : category.name_en}
                      </Box>
                      {category.children && category.children.length > 0 && (
                        <Center borderRadius="full" bg="white" boxSize="30px">
                          <RightOutlined />
                        </Center>
                      )}
                    </HStack>
                  </Button>
                );
              })}
            </>
          ) : (
            <>
              {productMainCategories.map((category, index) => {
                return (
                  <Button
                    variant="unstyled"
                    key={index}
                    width="100%"
                    height="auto"
                    onClick={() => {
                      handleParentCategoryClick(category);
                    }}
                  >
                    <HStack
                      borderBottom="1px solid #cdcdcd"
                      key={index}
                      py={3}
                      px={5}
                      justifyContent="space-between"
                    >
                      <HStack>
                        <Center flex="0 0 30px" w="30px">
                          <Image
                            src={
                              category.id === 1
                                ? '/image-icon/lighting-icon.png'
                                : category.id === 2
                                ? '/image-icon/smart-home-icon.png'
                                : '/image-icon/decoration-icon.png'
                            }
                            width={
                              category.id === 1
                                ? 18
                                : category.id === 2
                                ? 26
                                : 22
                            }
                            height={30}
                            alt="icon"
                          ></Image>
                        </Center>
                        <Box textTransform="uppercase">
                          {locale === 'th'
                            ? category.name_th
                            : category.name_en &&
                              category.name_en === 'Lighting'
                            ? 'Lighting & Electric Devices'
                            : category.name_en === 'Smart Home'
                            ? 'Smart Home Solution'
                            : category.name_en}
                        </Box>
                      </HStack>
                      <Center borderRadius="full" bg="white" boxSize="28px">
                        <RightOutlined />
                      </Center>
                    </HStack>
                  </Button>
                );
              })}
            </>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default LayoutHeaderMenuMobile;
