import { IAppBreadcrumbItem } from 'components/breadcrumb/AppBreadcrumb';
import { IBreadcrumb } from 'interfaces/breadcrumb.interface';
import { IProductCategory } from 'interfaces/product-category';
import { uniqBy } from 'lodash';
import { useRouter } from 'next/router';
import queryString from 'query-string';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import useCatchError from './useCatchError';
import useProductCategories from './useProductCategories';
import useProductMainCategories from './useProductMainCategories';

export default function useProductCategoriesArchive() {
  // use catch error hook
  const catchError = useCatchError();

  // use router
  const router = useRouter();

  const { permalink } = router.query;

  // log permalink
  console.log('permalink', permalink);

  // get all product categories
  const { productCategories, productCategoryTree } = useProductCategories(true);

  // get product main categories
  const { productMainCategories } = useProductMainCategories();

  // init breadcrumb
  const [breadcrumb, setBreadcrumb] = useState<IAppBreadcrumbItem[]>([]);

  // init product categories selected
  const [productCategoriesSelected, setProductCategoriesSelected] = useState<
    IProductCategory[]
  >([]);

  // init current parent category
  const [currentParentCategory, setCurrentParentCategory] =
    useState<IProductCategory | null>(null);

  // init current category index
  const [currentParentCategoryIndexes, setCurrentParentCategoryIndexes] =
    useState<number[]>([0]);

  // init current category selected ids
  const [currentCategorySelectedIds, setCurrentCategorySelectedIds] = useState<
    number[]
  >([]);

  // get productCategories tree for lighting
  const productCategoriesTreeForLighting = useMemo(() => {
    if (productCategoryTree && productCategoryTree.length > 0) {
      return productCategoryTree.map((category) => ({
        ...category,
        children: category.children?.filter(
          (child) => child.mainCategoryId === 1
        ),
        mainCategory: productMainCategories.find(
          (mainCategory) => mainCategory.id === category.mainCategoryId
        ),
      }));
    }
  }, [productCategories, productCategoryTree, productMainCategories]);

  // log productCategoriesTreeForLighting
  console.log(
    'productCategoriesTreeForLighting',
    productCategoriesTreeForLighting
  );

  // get productCategories tree for smart-home
  const productCategoriesTreeForSmartHome = useMemo(() => {
    return productCategories.filter(
      (category) => category.mainCategoryId === 2 // 2 is mainCategoryId of smart-home
    );
  }, [productCategories]);

  // get productCategories tree for decoration-lamp
  const productCategoriesTreeForDecorationLampStyle = useMemo(() => {
    return productCategories
      .filter(
        (category) => category.mainCategoryId === 3 // 3 is mainCategoryId of decoration-lamp
      )
      .filter((category) => category.permalink.startsWith('type/')); // 1 is parentId of type
  }, [productCategories]);

  // get productCategories tree for decoration-lamp
  const productCategoriesTreeForDecorationLampFunction = useMemo(() => {
    return productCategories
      .filter(
        (category) => category.mainCategoryId === 3 // 3 is mainCategoryId of decoration-lamp
      )
      .filter((category) => category.permalink.startsWith('function/')); // 2 is parentId of function
  }, [productCategories, router.query]);

  /**
   * ** Note **
   * Get current parent category
   *
   * Expected result:
   * 1. If permalink is not empty, then find current parent category
   * 2. If permalink is empty, then set current parent category to null
   */
  useEffect(() => {
    if (productCategoryTree && productCategoryTree.length > 0) {
      let permalinkString = '';

      if (Array.isArray(permalink)) {
        if (permalink.length > 0) {
          if (
            permalink[0] === 'lighting' ||
            permalink[0] === 'smart-home' ||
            permalink[0] === 'decoration-lamp'
          ) {
            if (permalink.length > 1) {
              permalinkString = permalink[1];
            }
          } else {
            permalinkString = permalink[0];
          }
        }
      } else if (permalink) {
        permalinkString = permalink.toString();
      }

      // log permalinkString
      console.log('permalinkString', permalinkString);

      // find current parent category
      const _currentParentCategory = productCategoryTree.find(
        (category) => category.permalink === permalinkString
      );

      // log _currentParentCategory
      console.log('_currentParentCategory', _currentParentCategory);

      if (_currentParentCategory) {
        setCurrentParentCategory(_currentParentCategory);
      } else {
        setCurrentParentCategory(null);
      }
    }
  }, [permalink, productCategoryTree]);

  // get current category
  const currentCategory = useMemo(() => {
    // set permalink
    if (permalink && permalink.length > 0 && productCategories) {
      const permalinkWithSlash = Array.isArray(permalink)
        ? // last 2 permalink
          permalink.slice(-2).join('/')
        : permalink;

      // log permalinkWithSlash
      console.log('permalinkWithSlash', permalinkWithSlash);

      // find productCategories by lastPermalink
      const _currentCategory = productCategories.find(
        (category) => category.permalink === permalinkWithSlash
      );

      // log currentCategory
      console.log('_currentCategory', _currentCategory);

      return _currentCategory;
    }
  }, [permalink, productCategories]);
  /**
   * ** Note **
   * Handle when query change
   *
   * Expected result:
   * 1. If categoryIds is not empty, then set currentCategorySelectedIds to categoryIds
   * 2. If categoryIds is empty, then set currentCategorySelectedIds to []
   */
  useEffect(() => {
    if (router.query) {
      // log router.query
      console.log('router.query', router.query);
      if (router.query.categoryIds) {
        const categoryIds = Array.isArray(router.query.categoryIds)
          ? router.query.categoryIds
          : router.query.categoryIds.toString().split(',');

        // log categoryIds
        console.log('categoryIds', categoryIds);

        // convert category ids to number
        const categoryIdsNumber = categoryIds.map((categoryId) =>
          Number(categoryId)
        );

        // log categoryIdsNumber
        console.log('categoryIdsNumber', categoryIdsNumber);

        setCurrentCategorySelectedIds(categoryIdsNumber);
      } else {
        setCurrentCategorySelectedIds([]);
      }
    }
  }, [router.query]);

  /**
   * ** Note **
   * Handle category selected
   *
   * Expectation:
   * 1. If checked, add id to current category selected ids
   * 2. If unchecked, remove id from current category selected ids
   * 3. Push query to router
   *
   */
  const handleCategorySelected = (
    e: ChangeEvent<HTMLInputElement>,
    isRouterPush = true
  ) => {
    const queryParse = queryString.parseUrl(window.location.href);

    // if checked
    if (e.target.checked) {
      const _currentCategorySelectedIds = [
        ...currentCategorySelectedIds,
        Number(e.target.value),
      ];

      // add id to current category selected ids
      setCurrentCategorySelectedIds(_currentCategorySelectedIds);

      if (isRouterPush) {
        // push query to router
        router.push(
          `${queryParse.url}?${queryString.stringify({
            ...queryParse.query,
            categoryIds: _currentCategorySelectedIds.join(','),
            page: 1,
          })}`,
          undefined,
          { shallow: true }
        );
      }
    } else {
      // remove id from current category selected ids
      const _currentCategorySelectedIds = currentCategorySelectedIds.filter(
        (id) => id !== Number(e.target.value)
      );

      setCurrentCategorySelectedIds(_currentCategorySelectedIds);

      const _currentCategorySelectedIdsString =
        _currentCategorySelectedIds.join(',');

      if (isRouterPush) {
        // push query to router
        router.push(
          `${queryParse.url}?${queryString.stringify({
            ...queryParse.query,
            categoryIds:
              _currentCategorySelectedIds.length > 0
                ? _currentCategorySelectedIdsString === ''
                  ? undefined
                  : _currentCategorySelectedIdsString
                : undefined,
            page: 1,
          })}`,
          undefined,
          { shallow: true }
        );
      }
    }
  };

  useEffect(() => {
    /**
     * ** Note **
     * if productCategoriesSelected we will find index of productCategoryTree
     * that match with productCategoriesSelected parent id
     * then we will set currentParentCategoryIndexes to that index
     *
     * Expectation: currentParentCategoryIndexes will be [0, 1]
     */

    if (
      productCategoriesSelected &&
      productCategoriesSelected.length > 0 &&
      productCategoryTree &&
      productCategoryTree.length > 0
    ) {
      // log productCategoriesSelected
      console.log('productCategoriesSelected', productCategoriesSelected);

      // find index of productCategoryTree that match with productCategoriesSelected parent id
      const _productCategoryTreeIndexs = productCategoryTree.map((category) => {
        if (productCategoriesSelected.find((c) => c.parentId === category.id)) {
          return productCategoryTree.findIndex((c2) => c2.id === category.id);
        }
      });

      // log _productCategoryTreeIndexs
      console.log('_productCategoryTreeIndexs', _productCategoryTreeIndexs);

      const _currentParentCategoryIndexes = _productCategoryTreeIndexs.filter(
        (index) => index !== undefined
      ) as number[];

      if (_currentParentCategoryIndexes.length > 0) {
        setCurrentParentCategoryIndexes(_currentParentCategoryIndexes);
      }
    } else {
      if (currentParentCategory) {
        // log currentParentCategory
        console.log('currentParentCategory', currentParentCategory);

        // find current category index
        const currentParentCategoryIndexes = productCategoryTree.findIndex(
          (category) => category.id === currentParentCategory.id
        );

        if (currentParentCategoryIndexes !== -1) {
          setCurrentParentCategoryIndexes([currentParentCategoryIndexes]);
        } else {
          setCurrentParentCategoryIndexes([0]);
        }
      } else {
        setCurrentParentCategoryIndexes([0]);
      }
    }
  }, [productCategoriesSelected, currentParentCategory]);

  // handle set product categories selected when router change
  useEffect(() => {
    const handleRouteChange = async (url: string) => {
      const queryParsed = queryString.parseUrl(url);

      // log queryParsed
      console.log('queryParsed', queryParsed);

      const { query } = queryParsed;

      try {
        if (query.categoryIds) {
          const ids = query.categoryIds
            .toString()
            .split(',')
            .map((id) => +id);

          // log ids
          console.log('ids', ids);

          if (productCategories) {
            setProductCategoriesSelected(
              productCategories.filter((category) => ids.includes(category.id))
            );
          }
        } else {
          setProductCategoriesSelected([]);
        }
      } catch (error) {
        catchError(error);
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  // handle set product categories selected ids when initial load
  useEffect(() => {
    if (
      router.query.categoryIds &&
      router.query.categoryIds.length > 0 &&
      productCategories &&
      !productCategoriesSelected.length // do not set productCategoriesSelected if it has value
    ) {
      const ids = router.query.categoryIds
        .toString()
        .split(',')
        .map((id) => +id);

      setProductCategoriesSelected(
        productCategories.filter((category) => ids.includes(category.id))
      );
    }
  }, [router, productCategories]);

  // handle set current parent category index
  const handleSetCurrentParentCategoryIndexes = (index: number[]) => {
    setCurrentParentCategoryIndexes(index);
  };

  /**
   * ** Note **
   * Clear filter will remove all query string except page
   *
   * Expectation: Remove categoryIds and sort query string
   */
  const handleClearFilter = () => {
    const queryParsed = queryString.parseUrl(window.location.href);

    const { query } = queryParsed;

    const queryFormatted = {
      ...query,
      page: 1,
      categoryIds: undefined,
      sort: undefined,
    };
    // format query string
    const queryStringFormatted = queryString.stringify(queryFormatted);

    router
      .push(`${queryParsed.url}?${queryStringFormatted}`)
      .then(() => window.scrollTo(0, 0));
  };

  // get my account layout breadcrumb
  const [layoutBreadcrumb, setLayoutBreadcrumb] = useState([
    {
      label: 'หน้าแรก',
      href: '/',
    },
  ]);

  // create breadcrumb
  useEffect(() => {
    // router.query
    console.log('router.query', router.query);

    const { permalink } = router.query;

    let _breadcrumbs = [
      /* 
      {
        label: 'หน้าแรก',
        href: '/',
      },
     */
    ];

    // log currentParentCategory
    console.log('currentParentCategory', currentParentCategory);

    // if permalink is array
    if (Array.isArray(permalink)) {
      // if first item is equal to 'lighting' or 'smart-home' or 'decoration-lamp'
      // remove first item
      if (
        permalink[0] === 'lighting' ||
        permalink[0] === 'smart-home' ||
        permalink[0] === 'decoration-lamp'
      ) {
        // get main category by permalink[0]
        const mainCategory = productMainCategories.find(
          (mainCategory) => mainCategory.permalink === permalink[0]
        );

        // if mainCategory is not undefined
        if (mainCategory) {
          // push mainCategory to _breadcrumbs
          _breadcrumbs.push({
            label:
              router.locale === 'th'
                ? mainCategory.name_th
                : mainCategory.name_en,
            href: `/product-categories/${mainCategory.permalink}`,
          });
        }
      }

      // loop through permalink array and get category by permalink
      permalink.forEach((permalink) => {
        const category = productCategories.find(
          (category) => category.permalink === permalink
        );

        const mainCategory = productMainCategories.find(
          (mainCategory) => mainCategory.id === category?.mainCategoryId
        );

        // if category is not undefined
        if (category) {
          // push category to _breadcrumbs
          _breadcrumbs.push({
            label: router.locale === 'th' ? category.name_th : category.name_en,
            href: `/product-categories/${mainCategory?.permalink}/${category.permalink}`,
          });
        }
      });
    }

    if (currentCategory) {
      // get main category by
      const mainCategory = productMainCategories.find(
        (mainCategory) => mainCategory.id === currentCategory.mainCategoryId
      );

      // if mainCategory is not undefined
      if (mainCategory) {
        // prepend mainCategory to _breadcrumbs
        if (mainCategory.permalink !== 'lighting') {
          _breadcrumbs.unshift({
            label:
              router.locale === 'th'
                ? mainCategory.name_th
                : mainCategory.name_en,
            href:
              mainCategory.permalink === 'smart-home'
                ? '/product-categories/smart-home/?productType=smart-home'
                : mainCategory.permalink === 'decoration-lamp'
                ? `/product-categories/${mainCategory.permalink}/?productType=decoration`
                : '/',
          });
        }

        // log mainCategory
        console.log('mainCategory', mainCategory);

        // push currentCategory to _breadcrumbs
        _breadcrumbs.push({
          label:
            router.locale === 'th'
              ? currentCategory.name_th
              : currentCategory.name_en,
          href: ``,
        });
      }
    }

    // remove duplicate breadcrumb
    _breadcrumbs = uniqBy(_breadcrumbs, 'href');

    _breadcrumbs = uniqBy(_breadcrumbs, 'label');

    if (_breadcrumbs.length > 0) {
      if (currentCategory?.mainCategoryId !== 1) {
        _breadcrumbs = _breadcrumbs.filter(
          (breadcrumb) =>
            breadcrumb.href !== '/product-categories/lighting/type'
        );
      }
    }

    // remove 2rd breadcrumb if it is '/product-categories/lighting/xxx'
    if (_breadcrumbs.length > 1) {
      if (_breadcrumbs[1].href.includes('/product-categories/lighting/')) {
        _breadcrumbs.splice(1, 1);
      }
    }

    // log _breadcrumbs
    console.log('_breadcrumbs', _breadcrumbs);

    // set breadcrumb
    setBreadcrumb(_breadcrumbs);
  }, [router, productMainCategories, productCategories, currentCategory]);

  return {
    productCategories: productCategories || [],
    productCategoryTree: productCategoryTree,
    productCategoriesTreeForLighting,
    productCategoriesTreeForSmartHome,
    productCategoriesTreeForDecorationLampFunction,
    productCategoriesTreeForDecorationLampStyle,
    productCategoriesSelected,
    productCategoriesSelectedIds: currentCategorySelectedIds,
    currentParentCategory,
    currentParentCategoryIndexes,
    breadcrumb,
    handleCategorySelected,
    handleSetCurrentParentCategoryIndexes,
    handleClearFilter,
  };
}
